import * as MCSDK from "./mcAppJsSDK";
import { isTestEnv } from "./other";

// nativeInfo: {
//   name: MC | MCS | MS | NANO | WEB
//   auth: MC | MS | NANO
//   theme: MC | MCS | MS | NANO
//   device
//   deviceId
//   osVersion
//   appVersion
//   region
//   lang
//   companyId
//   staffId
// };

const reactNativeMessageResolveMap = {};

window._onReactNativeMessage = (id, content) => {
  if (reactNativeMessageResolveMap[id]) {
    reactNativeMessageResolveMap[id](content);
  }
};

window._postReactNativeMessage = content => new Promise(resolve => {
  const id = Date.now() + `${Math.random()}`.slice(2);
  reactNativeMessageResolveMap[id] = resolve;
  window.ReactNativeWebView?.postMessage?.(JSON.stringify({ id, content }));
});

export const callRNNativeAsyncFunc = async (name, data) => {
  return window._postReactNativeMessage({ name, data });
};

const ensureViladObject = i => (i && typeof i === "object") ? i : {};

export const getNativeInfo = (() => {
  let nativeInfo;

  return () => {
    if (!nativeInfo) {
      // 测试用
      if (isTestEnv()) {
        const search = window.location.search;
        if (search.indexOf("native=MC") > -1) {
          nativeInfo = Promise.resolve({ name: "MC" });
          return nativeInfo;
        }
        if (search.indexOf("native=MS") > -1) {
          nativeInfo = Promise.resolve({ name: "MS" });
          return nativeInfo;
        }
      }
      nativeInfo = new Promise(async resolve => {
        if (/(\?|&)(mcKey|key)=/.test(window.location.search)) {
          // 黑端、蓝端
          const mcAppInfo = await MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.SYSTEM.USER_DATA);
          if (mcAppInfo) {
            try { mcAppInfo.mobile_info = JSON.parse(mcAppInfo.mobile_info); } catch (error) { }
            try { mcAppInfo.shops = JSON.parse(mcAppInfo.shops); } catch (error) { }
          }
          resolve({
            ...ensureViladObject(mcAppInfo),
            name: +mcAppInfo.app_pid === 62 ? "MCS" : "MC", // 61:黑端 | 62:蓝端
            device: mcAppInfo?.mobile_info?.modelName,
            deviceId: mcAppInfo?.device_sn,
            osVersion: mcAppInfo?.mobile_info?.systemVersion,
            appVersion: mcAppInfo?.app_version,
            region: mcAppInfo?.system_region,
            lang: mcAppInfo?.language_key,
            companyId: mcAppInfo?.company_id,
            staffId: mcAppInfo?.staff_id,
          });
        } else if (/(\?|&)(loginToken)=/.test(window.location.search)) {
          // 紫端、Nanoshop
          const info = await callRNNativeAsyncFunc("getNativeInfo");
          callRNNativeAsyncFunc("log", info);
          resolve(ensureViladObject(info));
        } else {
          resolve({ name: "WEB" });
        }
      });
    }

    return nativeInfo;
  };
})();

export const callNativeClose = () => getNativeInfo().then(info => {
  if (["MC", "MCS"].includes(info.name)) {
    return MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.SYSTEM.CLOSE);
  }
  if (["MS", "NANO"].includes(info.name)) {
    return callRNNativeAsyncFunc("close");
  }
});

export const callMCCreateGoods = mcTempGoodsCode => MCSDK.callNativeAsyncFunc(MCSDK.NativeFuncs.NAVIGATION.GENERAL, {
  "route": "/create_module",
  "params": { "key": "product_air_into", "present": "1", "id": mcTempGoodsCode },
});

export const callNativeOpenURL = url => {

};
