import React from "react";
import { Panel, Tips } from "ui-m/rn";



const LabelBlockContainer = props => {
  const { style, ...rest } = props;

  return (
    <Panel.Padding
      style={[{ paddingTop: 20 }, style]}
      {...rest}
    />
  );
};

// 紫端有完整版
const LabelBlock = props => {
  const { containerStyle, ...rest } = props;

  return (
    <LabelBlockContainer
      style={containerStyle}
    >
      <Tips type="desc" {...rest} />
    </LabelBlockContainer>
  );
};

LabelBlock.Container = LabelBlockContainer;

export default LabelBlock;
