import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SkeletonBlock } from 'ui-m/rn';

class SkeletonBlockCustomer extends React.PureComponent {
  render() {
    let arr = new Array(8).fill(1);
    return (
      <View style={styles.container}>
        {
          arr.map((i, ii) => (
            <SkeletonBlock key={ii} style={[styles.base, !(ii % 2) && styles.short, !ii && {marginTop: 0}]} />
          ))
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#FFF',
  },
  block: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
  base: {
    width: 245,
    height: 16,
    marginTop: 18,
    borderRadius: 2,
  },
  short: {
    width: 180,
  },

});
export default SkeletonBlockCustomer;
