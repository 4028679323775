import { create } from 'dva-core';
import * as frameworkModels from './framework/models';
import * as models from './models';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
import { setStore } from './utils/store';

const persistConfig = {
  key: 'redux-persist-store',
  storage: AsyncStorage,
  whitelist: ['auth', 'localStore'],
};
const persistEnhancer = () => createStore => (reducer, initialState, enhancer) => createStore(persistReducer(persistConfig, reducer), initialState, enhancer);

const dvaOption = {
  initialState: {},
  models: [...Object.values(frameworkModels), ...Object.values(models)],
  extraEnhancers: [ persistEnhancer() ],
  onError(e) {
    console.log('onError', e);
  },
};

const app = create(dvaOption);
// HMR workaround
if (!global.registered) {dvaOption.models.forEach(model => app.model(model));}
global.registered = true;

app.start();

persistStore(app._store, {}, ()=>{
  setStore(app._store);
});

export default app;
