import qs from 'qs';
import { mcRequest, authority } from '../utils';

export const getCompanyInfo = async () => mcRequest(`/user/get_company_info`, {
  method: 'GET',
});

export const getStaffInfo = async () => mcRequest(`/user/self_staff_info`, {
  method: 'GET',
});
