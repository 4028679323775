import { mcRequest } from "../utils";
import { getMcKey } from "@globalUtils";

export const getList = async (params = {}) => {
  const { pageNumber, pageSize, keyword } = params;

  const url = keyword ? `/customer/search` : "/customer/get_by_order";

  const data = {
    ...params,
    key: getMcKey(),
    page: pageNumber,
    page_num: pageSize,
    keyword,
  };

  if (keyword) {
    data.type = "company_name";
    data.client_type = "2";
  } else {
    data.type = "2";
  }

  const res = await mcRequest(url, { method: "POST", data });

  if (res.success) {
    const totalRecords = Number(res.data.list_num);

    res.data.resultSet = res.data.list;
    res.data.currentPage = pageNumber;
    res.data.pageNumber = pageNumber;
    res.data.pageSize = pageSize;
    res.data.totalRecords = totalRecords;
    res.data.totalPages = Math.ceil(totalRecords / pageSize);
    res.data.keyword = keyword;
  }

  return res;
};

export const getSingle = async id =>  mcRequest("/customer/get", {
  method: "POST",
  data: {
    key: getMcKey(),
    id,
  },
});
