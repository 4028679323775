import { Platform } from 'react-native';
import { delay, getNativeInfo } from '../../utils';
import * as daService from '../../services/dataAnalyses';
import kvlogService from '../../services/kvlog';
import RNLocalize from 'react-native-localize';
import { Theme } from 'ui-m/rn';
import { loadTheme } from 'theme';

let timeZone = '';
try {
  timeZone = RNLocalize.getTimeZone();
} catch (error) {
  kvlogService.postException(error);
}

// LifeCycle: 临时缓存/标记
let _appReadyEventEmitable = false;
let _checkAndEmitAppReadyEventsPayloadBuffer = {};
let _checkAndEmitAppReadyEventsBufferTmo;
function _reinitcheckAndEmitAppReadyEventsPayloadBuffer() {
  _checkAndEmitAppReadyEventsPayloadBuffer = {
    emitByLaunched: false,
    URLOpen: undefined,
    notificationOpen: undefined,
  };
}
_reinitcheckAndEmitAppReadyEventsPayloadBuffer();
let _iOSFirstAppStateChangeEmittedPromiseResolve;
let _iOSFirstAppStateChangeEmittedPromise = new Promise((resolve) => {
  _iOSFirstAppStateChangeEmittedPromiseResolve = resolve;
});
// /

export default {
  namespace: 'app',
  state: {
    // ready 当前的含义： 网络权限已赋予；Splash已隐藏；界面已开始渲染。
    isReady: false,
    // // 全局系统配置信息
    // sysConf: {
    // },
    nativeInfo: {
      name: "WEB",
      // name: WEB | MS | MCS | MC |  NANO
      // device
      // deviceId
      // osVersion
      // appVersion
      // region
      // lang
      // companyId
      // staffId
    },
    mcAppInfo: {
      // language_key: 'chs',
      // system_region: 'FR',
      // app_version: '2.42.0'
      // font_scale: 1,
      // device_sn: '...',
      // token: '',
      // mobile_info: {
      //   systemVersion: '16.0.3',
      //   modelName: 'iPhone13, 1',
      // },
      // phone: 15857191253
      // company_id: 1011,
      // staff_id: 1,
      // default_shop_id,
      // default_warehouse_id,
      // shops: [],
      // role: 'admin',
    },
    timeZone,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *init(action, { call, put, select, take, fork, all, race }) {
      const isReady = yield select(({ app }) => app.isReady);
      if (isReady) {
        return;
      }
      // --- ↓ 等待本地缓存加载
      yield all([
        // 本地缓存 model 读取
        take('persist/REHYDRATE'),
        // MC APP 信息读取
        yield put({ type: 'loadNativeInfo' }),
      ]);
      // --- ↑ 本地缓存加载完成
      // 多语言初始化
      const nativeInfo = yield select(({ app }) => app.nativeInfo);
      yield put({
        type: 'i18n/init', payload: {
          deviceRegion: nativeInfo.region,
          // deviceLang: nativeInfo.lang, // 优先用URL中的语言
        },
      });
      // 从 URL 中初始化登录信息
      yield put({ type: 'auth/init', payload: nativeInfo });
      // 打点: 打开App, TODO: 登录态失效的那一次打开APP，会漏掉打点
      yield fork(function* () {
        yield call(daService.create, { type: 'open' });
      });
      // // 本地化
      // yield put({ type: 'auth/initCountry' });
      // 标记APP初始化完成
      yield put({
        type: 'setState', payload: {
          isReady: true,
        },
      });
      // 处理意向
      yield put({ type: 'intention/init' });

      // LifeCycle: 通知可以抛出 appReady 生命周期事件了，并立即兜底触发一次
      _appReadyEventEmitable = true;

      // LifeCycle: 如果是 iOS，还得等下第一次触发 AppStateChange 事件（和安卓平台不能抹平的唯一差异，一个不固定的等待时间）
      if (Platform.OS === 'ios') {
        yield race([
          _iOSFirstAppStateChangeEmittedPromise,
          // 仅开发环境：有个问题是重新载入 RN JS 时，AppStateChange 事件不会再次触发，所以需要等待一段时间再触发一次
          ...(__DEV__ ? [delay(3000)] : []),
        ]);
      }
      // //  初始化意见反馈悬浮入口

      yield put({ type: 'checkAndEmitAppReadyEvents' });

      yield put({ type: 'lifeCycle_appReadyLaunched' });
    },
    *loadNativeInfo({ payload = {} } = {}, { call, put, select, take, fork, all, race }) {
      try {
        let nativeInfo = yield race([
          call(getNativeInfo),
          call(delay, 3000),
        ]);
        if (Array.isArray(nativeInfo)) {
          nativeInfo = nativeInfo[0];
        }
        if (nativeInfo) {
          yield put({ type: 'setState', payload: { nativeInfo } });
        }
      } catch (error) {
        console.warn('app/loadNativeInfo failed', error);
      }
      const nativeInfo = yield select(state => state.app.nativeInfo);
      yield loadTheme(nativeInfo.name);
      return true;
    },
    // *fetchRequiredResourcesOfApp({payload = {}} = {}, { call, put, select, take, fork, all }) {
    //   const {
    //     showLoading,
    //   } = payload;
    //   if (showLoading) {
    //     ScreenLoading.show();
    //   }
    //   const results =  yield all([
    //     // // 并行加载系统配置
    //     // yield put({ type: 'fetchSysConf'}),
    //     // 并行加载国家列表
    //     yield put({ type: 'country/getList' }),
    //   ]);
    //   if (showLoading) {
    //     ScreenLoading.hide();
    //   }
    //   return results.filter(_=>!_.success)[0] || results[0];
    // },
    // *ensureRequiredFetched({payload} = {}, { call, put, select, take, fork, all }) {
    //   let res = yield yield put({ type: 'fetchRequiredResourcesOfApp', payload });
    //   if (!res.success) {
    //     // 等待重试直到成功
    //     let resolve;
    //     const promise = new Promise((_resolve)=>{ resolve = _resolve; });
    //     yield fork(function*(){
    //       const dialogRes = yield Dialog.show({
    //         type: 'reload',
    //       });
    //       if (dialogRes === 'right') {
    //         // 无限重试
    //         res = yield yield put({ type: 'ensureRequiredFetched', payload: { showLoading: true }});
    //         if (res.success) {
    //           resolve();
    //         }
    //       }
    //     });
    //     // 在 Dialog之上弹出错误提示 Toast
    //     yield call(handleRequestErrorInModel, res);
    //     // 等待到成功
    //     yield promise;
    //   }
    //   return res;
    // },
    // *fetchSysConf(action, { call, put, select, take, fork }) {
    //   const res = yield call(appService.getSysConf);
    //   if (res.success) {
    //     yield put({ type: 'setState', payload: {
    //       sysConf: res.data || {},
    //     }});
    //   }
    //   return res;
    // },
    /**
     * 需测试的情况：
     * {
     *   Platform.OS: ['ios', 'android'],
     *   emitByLaunched: [true, false],
     *   URLOpen: [null, 'scheme://url']
     *   notificationOpen: [null, {...data...}], // 未验证
     *   backgroundLaunching: [false, true], // 特殊情况：启动过程被收起到后台，等待启动完毕后才聚焦 App
     * }
     */
    lifeCycle_appReadyLaunched({ payload = {} }, { call, put, select, fork }) { },
    lifeCycle_appReadyLaunchedOrFocused({ payload = {} }, { call, put, select, fork }) {
      // const {
      //   emitByLaunched = false,
      //   URLOpen = undefined,
      //   notificationOpen = undefined,
      // } = payload;
    },
  },
  subscriptions: {
    async setup({ dispatch }) {
      dispatch({ type: 'init' });
    },
  },
};
