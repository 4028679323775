import _ from 'lodash';
import { createAction } from '../../../utils';
import {factory as sourceFactory, reduceExtender} from './baseFactory';
// TODO：patch 只传变化了的字段

export {reduceExtender};

const LIST_TYPE_MULTI_PAGE = 'multi_page';
const LIST_TYPE_SINGLE_PAGE = 'single_page';
const LIST_TYPE_LOAD_MORE = 'load_more';

export const listTypes = {
  LIST_TYPE_MULTI_PAGE,
  LIST_TYPE_SINGLE_PAGE,
  LIST_TYPE_LOAD_MORE,
};

export const factory = ({
  namespace,
  service,
  listType = LIST_TYPE_LOAD_MORE,
  extender = origin=>({}),
})=>sourceFactory({
  namespace,
  extender: (sourceOrigin)=>reduceExtender({
    state: {
      fromRESTFulBaseModel: true,
      list: {
        resultSet: [],
        statistics: {
        },
        pagination: {},
        // initialPagination 会在 model 初始化时被复制到 pagination
        initialPagination: {
          // LIST_TYPE_MULTI_PAGE, LIST_TYPE_LOAD_MORE
          pageNumber: 1,  // 将要获取的数据的页码，或当前页码（加载完成时）
          currentPage: 1, // 当前数据对应的页码
          pageSize: 20,
          totalPages: 1,
          totalRecords: 0,
        },
        filter: {},
        // initialFilter 会在 model 初始化时被复制到 filter
        initialFilter: {
          keyword: undefined,
        },
        // Computed
        hasMore: true,  // 默认为 true 表示初始态（未加载过数据），() => pagination.currentPage < pagination.totalPages
        resultFilter: {}, // 与当前 resultSet 结果匹配的入参 filter
        // emptyType: {
        //   initialLoading: true,   // () => !resultSet.length && hasMore
        //   noContent: false,       // () => !resultSet.length && !hasMore && !filter.keyword
        //   searchNoResult: false,  // () => !hasMore && !!filter.keyword
        // },
      },
      form: {

      },
      profile: {

      },
      // Loading Mark
      loading: false,
      isListReloading: true,
      isListLoadingMore: false,
      isDetailReloading: false,
      isFromSubmitting: false,
      isDeleting: false,
      // Computed
      shouldListLoadMore: false,
    },
    reducers: {
      resetFilter(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            filter: {
              ...state.list.initialFilter,
              ...payload,
            },
          },
        };
      },
      resetPagination(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            pagination: {
              ...state.list.initialPagination,
              ...payload,
            },
          },
        };
      },
      resetHasMore(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            hasMore: true,
          },
        };
      },
      setFilter(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            filter: {
              ...state.list.filter,
              ...payload,
            },
          },
        };
      },
      setInitialFilter(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            initialFilter: {
              ...state.list.initialFilter,
              ...payload,
            },
          },
        };
      },
      setPagination(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            pagination: {
              ...state.list.pagination,
              ...payload,
            },
          },
        };
      },
      setInitialPagination(state, { payload }) {
        return {
          ...state,
          list: {
            ...state.list,
            initialPagination: {
              ...state.list.initialPagination,
              ...payload,
            },
          },
        };
      },
      setList(state, {payload}) {
        const {
          resultSet,
          currentPage,
          totalPages,
          pageSize,
          totalRecords,
          statistics,
          resultFilter,
        } = payload;
        // Compute hasMore
        let hasMore;
        if (currentPage !== undefined && totalPages !== undefined) {
          hasMore = currentPage < totalPages;
        }
        return {
          ...state,
          list: {
            ...state.list,
            resultSet,
            statistics: {
              ...state.list.statistics,
              ...(statistics || {}),
            },
            pagination: {
              ...state.list.pagination,
              ...(currentPage !== undefined ? {currentPage} : {}),
              ...(totalPages !== undefined ? {totalPages} : {}),
              ...(pageSize !== undefined ? {pageSize} : {}),
              ...(totalRecords !== undefined ? {totalRecords} : {}),
            },
            resultFilter: resultFilter !== undefined ? resultFilter : state.list.resultFilter,
            ...(hasMore !== undefined ? {hasMore} : {}),
          },
        };
      },
      setForm(state, {payload}) {
        return {
          ...state,
          form: {
            ...state.form,
            ...payload,
          },
        };
      },
      setProfile(state, {payload}) {
        return {
          ...state,
          profile: {
            ...payload,
          },
        };
      },
      resetForm(state, {payload}) {
        return {
          ...state,
          form: {
            ...(payload || state.profile),
          },
        };
      },
      setLoading(state, {payload}) {
        const {
          isListReloading,
          isListLoadingMore,
          isDetailReloading,
          isFromSubmitting,
        } = payload;
        return {
          ...state,
          ...(isListReloading !== undefined ? {isListReloading} : {}),
          ...(isListLoadingMore !== undefined ? {isListLoadingMore} : {}),
          ...(isDetailReloading !== undefined ? {isDetailReloading} : {}),
          ...(isFromSubmitting !== undefined ? {isFromSubmitting} : {}),
        };
      },
      removeListItem(state, {payload}) {
        const updatedData = payload;
        const resultSet = Array.isArray(state.list.resultSet) ? state.list.resultSet : [];
        const newResultSet = resultSet.filter( item => item.id != updatedData.id);
        return {
          ...state,
          list: {
            ...state.list,
            resultSet: newResultSet,
          },
        };
      },
      setListItem(state, {payload}) {
        const updatedData = payload;
        const resultSet = Array.isArray(state.list.resultSet) ? state.list.resultSet : [];
        const newResultSet = resultSet.map( item => {
          if (item.id == updatedData.id ) {
            return updatedData;
          }
          else {
            return item;
          }
        });
        return {
          ...state,
          list: {
            ...state.list,
            resultSet: newResultSet,
          },
        };
      },
      compute(state) {
        return {
          ...state,
          loading: (
            state.isListReloading ||
              state.isListLoadingMore ||
              state.isDetailReloading ||
              state.isFromSubmitting ||
              state.isDeleting
          ),
          shouldListLoadMore: state.list.hasMore && !state.isListReloading && !state.isListLoadingMore,
        };
      },
    },
    effects: {
      *reloadList({payload}, { call, put, select }) {
        yield put(createAction('setLoading')({isListReloading: true}));
        // 「下翻查看更多」场景，需要重置页码
        if ( listType === LIST_TYPE_LOAD_MORE ) {
          yield put(createAction('resetPagination')());
        }
        const { filter, pagination } = yield select((store) => ({
          filter: store[namespace].list.filter,
          pagination: store[namespace].list.pagination,
        }));
        const resultFilterStr = JSON.stringify(filter);
        const resultFilter = JSON.parse(resultFilterStr);
        const res = yield call(service.getList, {
          ...filter,
          ..._.pick(pagination, ['pageNumber' , 'pageSize']),
          ...payload,
        });
        if (res.success ) {
          const { filterNow  } = yield select((store) => ({
            filterNow: store[namespace].list.filter,
          }));
          if (resultFilterStr === JSON.stringify(filterNow)) {
            yield put(createAction('setList')({
              ...res.data,
              resultFilter,
            }));
          }
        }
        yield yield put(createAction("reloadListEnd")(res));
        yield put(createAction('setLoading')({isListReloading: false}));
        return res;
      },
      *loadMore({payload}, { call, put, select }) {
        yield put(createAction('setLoading')({isListLoadingMore: true}));
        const { filter, pagination, resultSet } = yield select((store) => ({
          resultSet: store[namespace].list.resultSet,
          filter: store[namespace].list.filter,
          pagination: store[namespace].list.pagination,
        }));
        const res = yield call(service.getList, {
          ...filter,
          ...payload,
          pageNumber: pagination.currentPage + 1,
          pageSize: pagination.pageSize,
        });
        if (res.success ) {
          const { filterNow  } = yield select((store) => ({
            filterNow: store[namespace].list.filter,
          }));
          if (JSON.stringify(filter) === JSON.stringify(filterNow)) {
            yield put(createAction('setList')({
              ...res.data,
              resultSet: [...resultSet, ...res.data.resultSet],
            }));
          }
        }
        yield put(createAction('setLoading')({isListLoadingMore: false}));
        return res;
      },
      *reloadDetail({payload}, { call, put, select }) {
        yield put(createAction('setLoading')({isDetailReloading: true}));
        const res = yield call(service.getSingle, payload );
        if (res.success) {
          yield put(createAction('setForm')( res.data ));
          yield put(createAction('setProfile')( res.data || {} ));
        }
        yield put(createAction('setLoading')({isDetailReloading: false}));
        return res;
      },
      *resetList({payload}, { call, put, select, all }) {
        yield all([
          put(createAction('resetPagination')()),
          put(createAction('resetHasMore')()),
          put(createAction('setList')({ resultSet: [], resultFilter: {} })),
        ]);
      },
      *resetDetail({payload}, { call, put, select }) {
        yield put(createAction('setForm')({}));
        yield put(createAction('setProfile')({}));
      },

      *submitForm(action, {call, put, select}) {
        const { isFromSubmitting } = yield select((store) => ({
          isFromSubmitting: store[namespace].isFromSubmitting,
        }));
        if (isFromSubmitting) {
          return ({success: false});
        }
        yield put(createAction('setLoading')({isFromSubmitting: true}));
        const {method = 'post', ...sourcePayload} = (action.payload || {});
        const {form} = yield select(states => ({
          form: states[namespace].form,
        }));
        const res = yield call(service[`${method.toLowerCase()}Single`], Object.keys(sourcePayload).length ? sourcePayload : form);
        if (res.success) {
          yield put(createAction('setProfileInfo')( res.data ));
          // TODO 更新 list 中的对应项
        }
        yield put(createAction('setLoading')({isFromSubmitting: false}));
        return res;
      },

      *submitPostForm({payload}, {call, put, select}) {
        const res = yield yield put(createAction('submitForm')({
          method: 'post',
          ...(payload || {}),
        }));
        yield put(createAction('submitPostForm_result')(res));
        return res;
      },
      *submitPatchForm({payload}, {call, put, select}) {
        const res = yield yield put(createAction('submitForm')({
          method: 'patch',
          ...(payload || {}),
        }));
        yield put(createAction('submitPatchForm_result')(res));
        return res;
      },
      *submitPutForm({payload}, {call, put, select}) {
        const res = yield yield put(createAction('submitForm')({
          method: 'put',
          ...(payload || {}),
        }));
        yield put(createAction('submitPutForm_result')(res));
        return res;
      },

      *delete(action, { call, put, select }) {
        yield put(createAction('isDeleting')({isFromSubmitting: true}));
        const res = yield call(service.deleteSingle, action.payload);
        if (res.success) {
          // TODO 删除 list 中的对应项
          yield put(createAction('delete_success')(action.payload));
        }
        yield put(createAction('isDeleting')({isFromSubmitting: false}));
        return res;
      },
      silenceUpdateListAfterSingleChange: [function*watch({ take, race, put, select, call, fork }) {
        yield fork(function*(){
          while (true) {
            const res = yield take('submitPatchForm_result');
            if (res?.payload?.success && res?.payload?.data?.id) {
              yield put(createAction('setListItem')(res.payload.data));
            }
          }
        });
        yield fork(function*(){
          while (true) {
            const res = yield take('submitPutForm_result');
            if (res?.payload?.success && res?.payload?.data?.id) {
              yield put(createAction('setListItem')(res.payload.data));
            }
          }
        });
        yield fork(function*(){
          while (true) {
            const res = yield take('delete_success');
            if (res?.payload?.id) {
              yield put(createAction('removeListItem')(res.payload));
            }
          }
        });
      }, { type: 'watcher' }],
      *submitPostForm_result(){},
      *submitPatchForm_result(){},
      *submitPutForm_result(){},
      *delete_success(){},
      watchAndCompute: [function*watch({ take, put, call, race }) {
        while (true) {
          yield race([
            take('setList'),
            take('setLoading'),
          ]);
          yield put(createAction('compute')());
        }
      }, { type: 'watcher' }],
    },
  }, extender(sourceOrigin)),
});
