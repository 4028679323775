import React from "react";
import { View, Animated, Easing } from "react-native";

class BorderProgress extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      top: new Animated.Value(0),
      right: new Animated.Value(0),
      bottom: new Animated.Value(0),
      left: new Animated.Value(0),
    };

  }

  get baseProgressStyle() {
    const { progressWidth, progressColor } = this.props;

    return {
      width: progressWidth,
      height: progressWidth,
      backgroundColor: progressColor,
      position: "absolute",
    };
  }

  componentDidMount() {
    this.onProgressChange(this.props.progress);
  }

  onProgressChange = progress => {
    progress = Number(progress);
    if (Number.isNaN(progress)) {
      return;
    }

    this._animation?.stop?.();

    // 0 <= progress: Int <= 100
    progress = Math.min(100, Math.max(0, Math.floor(progress)));
    this._animation = Animated.sequence(this.getAnimations(progress));

    this._animation?.start?.();
  }

  getAnimations = progress => {
    const { top, right, bottom, left } = this.state;
    const fromArr = [top, right, bottom, left].map(i => Math.floor(i._value));
    const from = fromArr.reduce((t, i) => t + i);
    const to = progress * 4;

    const sequence = [];

    let anchor = fromArr.findIndex(i => i < 100);
    anchor === -1 && (anchor = 3);

    const distance = to - from;
    const isIncrease = distance > 0;

    let remaining = distance;

    while (remaining) {
      const span = (
        isIncrease
          ? Math.min(remaining, 100 - fromArr[anchor])
          : Math.max(remaining, -fromArr[anchor])
      );

      remaining -= span;

      const animation = this.getAnimation(anchor, span, distance, !remaining);
      animation && sequence.push(animation);

      anchor += isIncrease ? 1 : -1;
    }

    return sequence;
  }

  getAnimation = (anchor, span, distance, isLast) => {
    let target;
    if (anchor === 0) {
      target = this.state.top;
    } else if (anchor === 1) {
      target = this.state.right;
    } else if (anchor === 2) {
      target = this.state.bottom;
    } else if (anchor === 3) {
      target = this.state.left;
    }

    // speed: 2 - 6
    const speed = 6 - Math.round(Math.abs(distance) / 100);

    return target && (
      Animated.timing(
        target,
        {
          toValue: Math.floor(target._value) + span,
          duration: Math.abs(span * speed) / 2,
          easing: (
            isLast
              ? Easing.inOut(Easing.ease)
              : Easing.linear
          ),
        }
      )
    );
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.progress !== this.props.progress) {
      this.onProgressChange(nextProps.progress);
    }
  }

  render() {
    const { style, children } = this.props;
    const { top, right, bottom, left } = this.state;

    return (
      <View style={style}>
        <View style={{ flex: 1 }}>{children}</View>
        <Animated.View
          style={{
            ...this.baseProgressStyle,
            width: top.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
            left: 0,
            top: 0,
          }}
        />
        <Animated.View
          style={{
            ...this.baseProgressStyle,
            height: right.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
            top: 0,
            right: 0,
          }}
        />
        <Animated.View
          style={{
            ...this.baseProgressStyle,
            width: bottom.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
            right: 0,
            bottom: 0,
          }}
        />
        <Animated.View
          style={{
            ...this.baseProgressStyle,
            height: left.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
            left: 0,
            bottom: 0,
          }}
        />
      </View>
    );
  }
}

class Progress {
  static Border = BorderProgress
}

export default Progress;
