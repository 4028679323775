import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { StyleSheet, View, Text, Image, TextInput } from "react-native";
import Touchable from "./Touchable";
import resources from "../config/resources";

const onClearBlurAndFocusInterval = 20;

const MCSearchBox = props => {
  const {
    value,
    onChangeText,
    onSubmitEditing,
    onFocus,
    onBlur,
    onCancel,
    autoFocus,
    returnKeyType = "search",
  } = props;
  const i18n = useSelector(state => state.i18n);
  const textInputRef = useRef();
  const innerProps = useRef({
    onBlurTimer: null,
  });

  const focus = useCallback(() => {
    textInputRef.current?.focus();
  }, []);

  const handleOnSubmitEditing = useCallback(() => {
    onSubmitEditing?.();
  }, [onSubmitEditing]);

  // onBlur延迟20ms触发, 延迟期内若onFocus 则忽略本次onBlur和onFocus
  const handleOnFocusAndBlur = useCallback(type => {
    if (type === "onBlur") {
      innerProps.current.onBlurTimer = setTimeout(() => {
        onBlur?.();
        innerProps.current.onBlurTimer = null;
      }, onClearBlurAndFocusInterval);
    }
    if (type === "onFocus") {
      if (innerProps.current.onBlurTimer) {
        clearTimeout(innerProps.current.onBlurTimer);
        innerProps.current.onBlurTimer = null;
      } else {
        onFocus?.();
      }
    }
  }, [onBlur, onFocus]);

  const handleOnFocus = useCallback(() => {
    handleOnFocusAndBlur("onFocus");
  }, [handleOnFocusAndBlur]);

  const handleOnBlur = useCallback(() => {
    handleOnFocusAndBlur("onBlur");
  }, [handleOnFocusAndBlur]);

  const handleOnClear = useCallback(() => {
    onChangeText("");
    focus();
  }, [focus, onChangeText]);

  const handleOnCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <View style={styles.wrap} >
      <Touchable
        style={styles.cancelButton}
        onPress={handleOnCancel}
      >
        <Text style={styles.cancelButtonText} >{i18n.map["cmn.btn.cancel"]}</Text>
      </Touchable>
      <View style={styles.textInputWrap}>
        <TextInput
          ref={textInputRef}
          style={styles.textInput}
          value={value}
          onChangeText={onChangeText}
          onSubmitEditing={handleOnSubmitEditing}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          placeholder={i18n.map["mch.plhd.search"]}
          placeholderTextColor="#C5C5C5"
          autoFocus={autoFocus}
          returnKeyType={returnKeyType}
          allowFontScaling={false}
        />
        <View
          style={styles.magnifier}
          pointerEvents="none"
        >
          <Image
            source={resources.searchMagnifier1}
            style={{ width: 18, height: 18 }}
          />
        </View>
        <Touchable
          style={styles.clear}
          activeOpacity={1}
          onPress={handleOnClear}
        >
          <Image
            source={resources.searchDelete1}
            style={{ width: 14, height: 14 }}
          />
        </Touchable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    width: "100%",
    backgroundColor: "#1E2023",
    paddingVertical: 6,
    paddingHorizontal: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  cancelButton: {
    alignSelf: "stretch",
    justifyContent: "center",
  },
  cancelButtonText: {
    fontSize: 17,
    lineHeight: 24,
    color: "#fff",
  },
  textInputWrap: {
    flex: 1,
    marginLeft: 10,
  },
  textInput: {
    height: 32,
    paddingLeft: 38,
    paddingRight: 34,
    paddingHorizontal: 6,
    fontSize: 15,
    lineHeight: 20,
    backgroundColor: "#fff",
    borderRadius: 4,
  },
  magnifier: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: 38,
    alignItems: "center",
    justifyContent: "center",
  },
  clear: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 34,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default MCSearchBox;
