import { Platform } from 'react-native';
import { Teaset, WindowSizeResponsive } from 'ui-m/rn';
import innerText from 'react-innertext';

export default class BasePage extends Teaset.BasePage {

  static defaultProps = {}

  static renderDocumentTitle = function (ctx) {
    const {
      title,
      renderNavigationTitle,
      documentTitle,
    } = ctx.props;

    let finalTitle = title;
    if (typeof documentTitle === "string") {
      finalTitle = documentTitle;
    } else if (typeof documentTitle === "function") {
      finalTitle = documentTitle.call(ctx);
    } else if (typeof renderNavigationTitle === "function") {
      finalTitle = renderNavigationTitle.call(ctx);
      finalTitle = typeof finalTitle === "string" ? finalTitle : innerText(finalTitle);
    }

    if (
      Platform.OS === "web" &&
      finalTitle &&
      finalTitle !== document.title
    ) {
      document.title = finalTitle;
    }
  }

  componentDidMount() {
    const debouncedForceUpdate = WindowSizeResponsive.generateDebouncedForceUpdateFn(this);
    this.__windowResizeForceUpdateHandler = WindowSizeResponsive.addListener(debouncedForceUpdate);
  }

  componentWillUnmount() {
    WindowSizeResponsive.removeListener(this.__windowResizeForceUpdateHandler);
  }

  render() {
    this.constructor.renderDocumentTitle(this);
    return super.render();
  }
}

