import { request } from "../utils";
import { getCompanyId } from "@globalUtils";
import qs from "qs";

export const getList = (params = {}) => request(
  `/downstream-companies/${getCompanyId()}/dashboard-viewers?${qs.stringify(params)}`
);

export const addViewer = supplierId => request(`/downstream-companies/${getCompanyId()}/dashboard-viewers`, {
  method: "POST",
  data: {
    supplierId: Number(supplierId),
  },
});

export const deleteViewer = supplierId => request(`/downstream-companies/${getCompanyId()}/dashboard-viewers/${supplierId}`, {
  method: "DELETE",
});

export const getActivatedViewerCount = () => request(`/downstream-companies/${getCompanyId()}/dashboard-viewers/activated-count`);

export const exportToExcel = params => request(`/downstream-companies/${getCompanyId()}/sales-overview/export`, {
  method: "POST",
  data: params,
});

export const getSupplierOverview = ({ supplierId, ...rest }) => request(`/downstream-companies/${getCompanyId()}/suppliers/${supplierId}/sales-overview?${qs.stringify(rest)}`);
