import React from "react";
import { Image, View } from "react-native";
import { Theme } from 'ui-m/rn';

const isSupprotWebp = (() => {
  let isSupport;

  return function() {
    if (isSupport === undefined) {
      const ua = window.navigator.userAgent.toLocaleUpperCase();
      // iphone "CPU IPHONE OS (.*?) LIKE MAC OS"
      // ipad    "IPAD; CPU OS (.*?) LIKE MAC OS"
      const iosVer = ua?.match?.(/OS (.*?) LIKE MAC OS/);
      const iosMianVer = Number(iosVer?.[1]?.split("_")[0]) || 0;

      isSupport = (
        !Theme.isIOSWeb ||
        iosMianVer >= 14
      );
    }
    return isSupport;
  };
})();

const smartCropWidth = (width) => {
  let smartWidth;
  if (width <= 50) {
    smartWidth = 50;
  } else if (width <= 100) {
    smartWidth = 100;
  } else if (width <= 1000) {
    smartWidth = Math.ceil(width / 100) * 100;
  } else {
    smartWidth = Math.ceil(width / 200) * 200;
  }
  return smartWidth;
};

const cropImage = (url, width) => {
  width = smartCropWidth(width);
  if (
    typeof url === "string" &&
    url.indexOf("http") === 0
  ) {
    const fileType = (url.split(".").pop() || "").toLocaleUpperCase();

    const handleWidth = typeof width === "number" && width;
    const handleWebp = (
      !["GIF", "WEBP"].includes(fileType) &&
      isSupprotWebp()
    );

    // 去掉黑端裁剪参数
    url = url.replace(/(user_(\d+)\/)_(\d+)_(\d+)_/, ($0, $1) => $1);

    if (handleWidth || handleWebp) {
      url += `${url.includes('?') ? '&' : '?'}x-oss-process=image`;
      handleWidth && (url += `/resize,w_${Math.round(width)}`);
      handleWebp && (url += `/format,webp`);
    }

  }

  return url;
};

const NetImage = props => {
  const { source = {}, url, ...rest } = props;
  url && (source.uri = url);

  return source.uri ? (
    <Image source={source} {...rest} />
  ) : (
    <View {...props} />
  );
};

NetImage.crop = cropImage;

export default NetImage;
