import _ from 'lodash';
import qs from 'qs';
import request from '../utils/request';
import NetInfo from '../utils/netInfo';
import { getCurrentShopId, getUserGuid, getLoginId } from '../utils/authority';
import DeviceInfo from 'react-native-device-info';
import packageJson from '../../package.json';
import { getLastRootState, getLastCurrentRoute } from '../utils/navigation';
import { appConf, envConf } from '../config';
import RNLocalize from 'react-native-localize';

export default {
  postException: async function  (err, extraInfo = {}) {
    const [
      accountId,
      strangerId,
      companyId,
      env,
      connectionType,
      connectionTypeDetail,
    ] = await Promise.all([
      getLoginId(),
      getUserGuid(),
      getCurrentShopId(),
      envConf.getCurrentEnv(),
      NetInfo.getConnectionType(),
      NetInfo.getConnectionTypeDetail(),
    ]);

    const {
      version,
      versionType,
      build,
      loginSource,
    } = appConf.get();

    const isError = err instanceof Error;
    const params = {
      method: 'POST',
      body: {
        key: 'JS_EXCEPTION_LOG',
        terminal: loginSource,
        version,
        versionType,
        versionBuild: build,
        accountId,
        strangerId,
        companyId,
        env,
        error: (isError ? err.toString() : err.error) || (err.feedbackId ? 'user feedback' : 'customized error'),
        detail: (isError ? JSON.stringify(err || {}) : err.detail) || JSON.stringify(err),
        navigationState: getLastRootState() || {},
        currentRoute: getLastCurrentRoute() || {},
        device: [DeviceInfo.getDeviceType(), DeviceInfo.getDeviceId(), DeviceInfo.getBrand(), DeviceInfo.getModel()].filter(_=>_).join(', '),
        deviceId: DeviceInfo.getUniqueId(),
        osVersion: DeviceInfo.getSystemName() + DeviceInfo.getSystemVersion() ,
        connectionType,
        connectionTypeDetail,
        locales: [RNLocalize.getCountry()].concat(RNLocalize.getLocales() || []),
        ...extraInfo,
        errorType: extraInfo.errorType || 'other',
      },
    };
    console.warn(`postException: ${params.body.error}, ${params.body.detail}`, params.body);
    return request('/kvlogs', params);
  },
};

