import AsyncStorage from '@react-native-community/async-storage';
import { getStore } from './store';


export async function getMcKey() {
  return (await getStore()).getState()?.auth?.mcKey;
}

// export async function getAuthority() {
//   return (await getStore()).getState()?.auth?.accountInfo?.roleTypeCode;
// }

export async function getLoginToken() {
  return (await getStore()).getState()?.auth?.loginToken;
}

export async function getLoginId() {
  return (await getStore()).getState()?.auth?.staffInfo?.id;
}

// export async function getLoginInfo() {
//   return (await getStore()).getState()?.auth?.accountInfo;
// }

export async function getCurrentShopId() {
  return (await getStore()).getState()?.auth?.companyInfo?.id;
}

export async function setLoginInfo(loginInfo) {
  return (await getStore()).dispatch({
    type: 'auth/setLoginInfo',
    payload: loginInfo,
  });
}

const userGuidStoreKey = `userGuid`;
let userGuid;

function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
}

export function resetUserGuid() {
  const _uuid = userGuid = uuid();
  AsyncStorage.setItem(userGuidStoreKey, _uuid);
  return _uuid;
}

export async function getUserGuid() {
  if (!userGuid) {
    let localUuid = await AsyncStorage.getItem(userGuidStoreKey);
    userGuid = localUuid || resetUserGuid();
  }
  return userGuid;
}

export async function getFacebookAccessToken() {
  
}
