import React, { useState } from "react";
import { View } from "react-native";
import { Teaset, Cell, CellGroup, Theme } from "ui-m/rn";
const { Drawer } = Teaset;

const PullCellPickerView = props => {
  const { items, onSelect } = props;
  const [selected, setSelected] = useState(props.selected);

  return (
    <CellGroup>
      {items.map((i, ii) => {
        if (!i || typeof i !== "object") {
          return null;
        }

        const { title, subtitle, footer } = i;

        return (
          <Cell
            key={`${ii}`}
            title={title}
            subtitle={subtitle}
            footer={footer}
            formType="select"
            value={selected === i.key}
            onPress={() => {
              setSelected(i.key);
              onSelect(i);
            }}
          />
        );
      })}
    </CellGroup>
  );
};

const open = params => {
  const { items, selected, onSelect } = params;

  let newSelected;
  let newSelectedItem;
  const drawer = Drawer.open(
    (
      <View style={{ paddingBottom: Theme.isIPhoneX ? 15 : 0 }}>
        <PullCellPickerView
          items={items}
          selected={selected}
          onSelect={item => {
            newSelectedItem = item;
            newSelected = item.key;
            drawer.close();
          }}
        />
      </View>
    ),
    "bottom",
    "none",
    {
      onDisappearCompleted: () => {
        if (newSelectedItem) {
          onSelect(newSelected, newSelectedItem);
        }
      },
    }
  );
};

PullCellPickerView.open = open;

export default PullCellPickerView;
