
import DeviceInfo from 'react-native-device-info';

const getConnectionType = () => {
  
};

const getConnectionTypeDetail = () => {
  
};

export default {
  getConnectionType,
  getConnectionTypeDetail,
};
