import { factory, listTypes } from '../framework/models/mixins/RESTfulBaseModel';
import * as dashboardSupplierService from '../services/dashboardSupplier';

const namespace = "dashboardSupplier";
export default factory({
  namespace,
  service: dashboardSupplierService,
  listType: listTypes.LIST_TYPE_LOAD_MORE,
  extender: (origin) => ({
    state: {
      // ... some extends
      initialFilter: {
        ...origin.state.initialFilter,
        // ... some extends
      },
      initialPagination: {
        ...origin.state.initialPagination,
        // ... some extends
      },
      noPermission: false,
    },
    reducers: {
      // ... some extends
    },
    effects: {
      // ... some extends
      *getSingle({ payload = {} }, { call, put, select }) {
        const res = yield call(dashboardSupplierService.getSingle, payload);

        return res;
      },
      *reloadListEnd({ payload = {} }, { call, put, select }) {
        const { noPermission } = yield select(state => state[namespace]);
        let _noPermission = noPermission;
        if (payload.success) {
          _noPermission = false;
        } else if (+payload.status === 403) {
          _noPermission = true;
        }
        if (noPermission !== _noPermission) {
          yield put({ type: "setState", payload: { noPermission: _noPermission } });
        }
      },
    },
  }),
});
