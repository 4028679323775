import * as globalServices from '../services/global';
import { handleRequestError } from '../utils';

export default {
  namespace: 'global',
  state: {
    merchantContacts: [],
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearMerchantContacts(state, { payload }) {
      return { ...state, merchantContacts: [] };
    },
  },
  effects: {
    *getMerchantContacts({ payload }, { call, put, select }) {
      const res = yield call(globalServices.getMerchantContacts, payload);

      if (res.success) {
        yield put({
          type: "setState",
          payload: {
            merchantContacts: res.data.merchantContacts,
          },
        });
      } else {
        handleRequestError(res);
      }

      return res;
    },
    watchLogin: [
      function* watch({ take, put, call, race, select }) {
        while (true) {
          // 未登陆 和 已登陆, 客服联系方式可能不同
          yield take("auth/loginViaMcQrCode/@@end");
          yield put({ type: "clearMerchantContacts" });
        }
      }, { type: 'watcher' },
    ],

    watchLogout: [
      function* watch({ take, put, call, race, select }) {
        while (true) {
          // 未登陆 和 已登陆, 客服联系方式可能不同
          yield take("auth/logout/@@end");
          yield put({ type: "clearMerchantContacts" });
        }
      }, { type: 'watcher' },
    ],
  },
};
