import { factory, listTypes } from '../framework/models/mixins/RESTfulBaseModel';
import * as dashboardViewerService from '../services/dashboardViewer';
import { handleRequestError, Storage } from './../utils';

const sortOptionStorageKey = "SORT_OPTION_dashboardViewerModel";
// todo: 可用性校验
const defaultSortOption = {
  sortTypeCode: "created_date",
  asc: false,
};

const namespace = "dashboardViewer";
export default factory({
  namespace,
  service: dashboardViewerService,
  listType: listTypes.LIST_TYPE_LOAD_MORE,
  extender: (origin) => ({
    state: {
      // ... some extends

      activatedViewerCount: 0,
      noPermission: false,
    },
    reducers: {
      // ... some extends
      setState(state, { payload }) {
        return {
          ...state,
          ...payload,
        };
      },
    },
    effects: {
      // ... some extends
      *getActivatedViewerCount({ payload }, { call, put, select }) {
        const res = yield call(dashboardViewerService.getActivatedViewerCount);

        if (res.success) {
          yield put({ type: "setState", payload: { activatedViewerCount: res.data.count } });
        } else {
          handleRequestError(res);
        }

        return res;
      },

      *addViewer({ payload }, { call, put, select }) {
        const res = yield call(dashboardViewerService.addViewer, payload);

        if (!res.success) {
          handleRequestError(res);
        }

        return res;
      },

      *deleteViewer({ payload }, { call, put, select }) {
        const res = yield call(dashboardViewerService.deleteViewer, payload);

        if (!res.success) {
          handleRequestError(res);
        }

        return res;
      },

      *exportToExcel({ payload }, { call, put, select }) {
        const res = yield call(dashboardViewerService.exportToExcel, payload);

        if (!res.success) {
          handleRequestError(res);
        }

        return res;
      },

      *getSupplierOverview({ payload }, { call, put, select }) {
        const res = yield call(dashboardViewerService.getSupplierOverview, payload);

        if (!res.success) {
          handleRequestError(res);
        }

        return res;
      },

      *reloadListEnd({ payload = {} }, { call, put, select }) {
        const { noPermission } = yield select(state => state[namespace]);
        let _noPermission = noPermission;
        if (payload.success) {
          _noPermission = false;
        } else if (+payload.status === 403) {
          _noPermission = true;
        }
        if (noPermission !== _noPermission) {
          yield put({ type: "setState", payload: { noPermission: _noPermission } });
        }
      },

      *saveSortOptionToStorage({ payload = {} }, { call, put, select }) {
        const { list } = yield select(state => state[namespace]);
        const sortOption = {
          sortTypeCode: list.filter.sortTypeCode,
          asc: list.filter.asc,
        };
        yield Storage.set(sortOptionStorageKey, sortOption);
      },

      *setSortOptionFromStorage({ payload = {} }, { call, put, select }) {
        const sortOption = yield Storage.get(sortOptionStorageKey);
        yield put({ type: "setFilter", payload: sortOption || defaultSortOption });
      },

      *setSortOption({ payload = {} }, { call, put, select }) {
        yield put({ type: "setFilter", payload: payload });
        yield put({ type: "saveSortOptionToStorage" }); // 异步存储, 不必阻塞
      },

    },
    subscriptions: {
      async setup({ dispatch }) {
        dispatch({ type: "setSortOptionFromStorage" });
      },
    },
  }),
});
