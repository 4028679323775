import { factory, listTypes } from '../framework/models/mixins/RESTfulBaseModel';
import * as supplierService from '../services/supplier';

export default factory({
  namespace: 'supplier',
  service: supplierService,
  listType: listTypes.LIST_TYPE_LOAD_MORE,
  extender: (origin) => ({
    state: {
      // ... some extends
      initialFilter: {
        ...origin.state.initialFilter,
        // ... some extends
      },
      initialPagination: {
        ...origin.state.initialPagination,
        // ... some extends
      },
    },
    reducers: {
      // ... some extends
    },
    effects: {
      // ... some extends
    },
  }),
});
