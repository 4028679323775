import * as prefereneceService from '../services/preference';
import { handleRequestErrorInModel } from '../utils';

export default {
  namespace: 'preference',
  state: {
    settings: null,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getPreference(action, { take, put, call, race, select }) {
      const res = yield call(prefereneceService.getPreference);
      if (res.success) {
        yield put({ type: "setState", payload: { settings: res.data } });
      } else {
        yield handleRequestErrorInModel(res);
      }
      return res;
    },
    *savePreference({ payload }, { call, put, select }) {
      yield put({ type: "setState", payload: { settings: payload } });
      const res = yield call(prefereneceService.putPreference, payload);

      if (res.success) {

      } else {
        yield handleRequestErrorInModel(res);
      }

      return res;
    },
  },
};
