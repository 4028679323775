import _ from "lodash";

const ensureValidObject = i => (i && typeof i === "object") ? i : ({});

const ensureArray = i => Array.isArray(i) ? i : [];

const ensureFunction = i => typeof i === "function" ? i : () => { };

const ensureAsyncFunction = fn => async (...rest) => ensureFunction(fn)(...rest);

const createStepper = () => {
  let i = 0;
  return () => ++i;
};

const safeFunction = fn => (...rest) => {
  try {
    const res = fn(...rest);
    return res;
  } catch (error) { }
};

const createSharedPromise = () => {
  let _resolve, _reject;
  const promise = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });
  return [promise, _resolve, _reject];
};

// todo: 性能优化
const isEqual = _.isEqual;

const getDiffKeys = (a, b) => {
  a = ensureValidObject(a);
  b = ensureValidObject(b);
  const keys = [...new Set([...Object.keys(a), ...Object.keys(b)])];
  return keys.filter(key => !isEqual(a[key], b[key]));
};

const createNoCitationState = (initialState) => {
  let state = _.cloneDeep(ensureValidObject(initialState));
  const get = () => state;
  const getItem = key => get()[key];

  const setTo = o => state = { ...ensureValidObject(o) };
  const set = o => setTo({
    ...get(),
    ...ensureValidObject(o),
  });

  const setItemTo = (key, value) => set({
    [`${key}`]: value,
  });
  const setItem = (key, value) => setItemTo(key, {
    ...ensureValidObject(getItem(key)),
    ...ensureValidObject(value),
  });

  return { get, getItem, set, setTo, setItem, setItemTo };

};

const singleRequester = (config) => {
  const stepper = createStepper();
  let request = async () => null;
  let current = null;

  const setRequest = _request => {
    if (typeof _request === "function") {
      request = ensureAsyncFunction(_request);
      cancel();
    }
  };
  const isRequesting = () => !!current;
  const cancel = () => {
    if (isRequesting()) {
      current.resolve();
      current = null;
    }
  };

  // request本应存到内部, 但
  const create = (params) => {
    if (
      isRequesting() &&
      !_.isEqual(current.params, params)
    ) {
      cancel();
    }

    if (!isRequesting()) {
      const id = stepper();
      const [promise, resolve, reject] = createSharedPromise();
      current = {
        id,
        request,
        params,
        promise,
        resolve,
        reject,
      };
      request(params).then(res => {
        if (id === current?.id) {
          resolve(res);
          current = null;
        } else {
          resolve(); // 保险
        }
      });
    }

    return current.promise;
  };

  const init = () => {
    setRequest(config?.request);
  };

  init();

  return {
    setRequest,
    isRequesting,
    create,
    cancel,
  };
};

class ReactiveState {

  constructor(config) {
    const {
      data,
      computed,
      watch,
    } = ensureValidObject(config);

    this.data.set(safeFunction(data)());
    this.initComputed(computed);
    this.initWatch(watch);
  }

  initComputed = computed => {
    Object.entries(ensureValidObject(computed)).map(this.addComputed);
    this.updateAllComputedValue();
  }

  initWatch = watch => {
    ensureArray(watch).map(this.addWatcher);
  }

  // 不外直接操作data, 避免把computed放进data
  data = createNoCitationState();
  computedMap = {};
  watchers = [];
  watcherStepper = createStepper();
  isAllWatcherDisabled = false;
  disableAllWatcher = () => this.isAllWatcherDisabled = true;
  enableAllWatcher = () => this.isAllWatcherDisabled = false;
  checkAllWatcherDisabled = () => this.isAllWatcherDisabled;

  getComputedData = () => {
    const data = {};
    for (const key in this.computedMap) {
      data[key] = this.computedMap[key].value;
    }
    return data;
  }

  updateAllComputedValue = () => {
    const computedData = this.getComputedData();
    for (const computed of Object.values(this.computedMap)) {
      computed.value = computed.getter(this.getState());
    }
    const nextComputedData = this.getComputedData();
    // 不断重新计算computed, 直到值不再变化
    if (!isEqual(computedData, nextComputedData)) {
      return this.updateAllComputedValue();
    }
  }

  _addComputed = (options) => {
    const key = [null, undefined].includes(options.key) ? null : `${options.key}`;
    const getter = typeof options.getter === "function" ? options.getter : null;
    if (
      key === null ||
      getter === null ||
      this.computedMap[key] // 是否应该覆盖, 待定
    ) {
      return;
    }
    this.computedMap[key] = {
      key,
      value: undefined,
      getter,
    };
    return this.computedMap[key];
  }

  addComputed = (...rest) => {
    // ( key, getter )
    if (typeof rest[0] === "string" && typeof rest[1] === "function") {
      return this._addComputed({
        key: rest[0],
        getter: rest[1],
      });
    }

    // ( [ key, getter ] )
    if (Array.isArray(rest[0]) && typeof rest[0][0] === "string" && typeof rest[0][1] === "function") {
      return this._addComputed({
        key: rest[0][0],
        getter: rest[0][1],
      });
    }

    return this._addComputed(...rest);
  }

  _addWatcher = (options) => {
    const id = `${this.watcherStepper()}`;
    const path = [null, undefined].includes(options.path) ? "*" : `${options.path}`;
    const handler = typeof options.handler === "function" ? safeFunction(options.handler) : () => null;
    const watcher = {
      id,
      path,
      handler,
      remove: () => {
        const index = this.watchers.findIndex(i => i.id === id);
        if (index > -1) {
          this.watchers[index] = null;
        }
      },
    };
    this.watchers.push(watcher);
    return watcher;
  }

  addWatcher = (...rest) => {
    // ( path, handler )
    if (typeof rest[0] === "string" && typeof rest[1] === "function") {
      return this._addWatcher({
        path: rest[0],
        handler: rest[1],
      });
    }
    // ( handler )
    if (typeof rest[0] === "function") {
      return this._addWatcher({
        path: "*",
        handler: rest[0],
      });
    }
    // ( [ path, handler ] )
    if (Array.isArray(rest[0]) && typeof rest[0][0] === "string" && typeof rest[0][1] === "function") {
      return this._addWatcher({
        path: rest[0][0],
        handler: rest[0][1],
      });
    }

    return this._addWatcher(...rest);
  }

  _checkStateChange = (state, nextState, isUnify) => {
    if (this.checkAllWatcherDisabled()) {
      return;
    }
    const diffKeys = getDiffKeys(state, nextState);
    if (!diffKeys.length) {
      return;
    }

    for (const watcher of this.watchers) {
      if (watcher.path === "**") {
        // isUnify 会跳过 path 为 ** 的 watch
        !isUnify && watcher.handler(nextState, state, nextState, state);
        continue;
      }
      if (watcher.path === "*") {
        watcher.handler(nextState, state, nextState, state);
        continue;
      }
      const value = _.get(state, watcher.path);
      const nextValue = _.get(nextState, watcher.path);
      if (!isEqual(value, nextValue)) {
        watcher.handler(nextValue, value, nextState, state);
      }
    }
  }

  _stateResponder = (fn, isUnify) => {
    const state = this.getState();
    const res = fn();
    this.updateAllComputedValue();
    this._checkStateChange(state, this.getState(), isUnify);
    return res;
  }

  _wrapStateResponder = (fn, isUnify) => {
    if (fn?._isWrappedStateResponder) {
      return fn;
    }

    const wrapped = (...rest) => this._stateResponder(() => fn(...rest), isUnify);
    wrapped._isWrappedStateResponder = true;

    return wrapped;
  }

  // state
  getState = () => {
    return ({
      ...this.data.get(),
      ...this.getComputedData(),
    });
  }

  removeKeyInComputedMap = o => {
    return Object.entries(ensureValidObject(o)).reduce((res, [key, value]) => {
      if (key in this.computedMap) {
        res[key] = undefined;
      } else {
        res[key] = value;
      }
      return res;
    }, {});
  }

  _setState = this._wrapStateResponder((data) => {
    this.data.set(this.removeKeyInComputedMap(data));
    this.updateAllComputedValue();
    return this.getState();
  })

  _setStateUnify = this._wrapStateResponder((data) => {
    this.data.set(this.removeKeyInComputedMap(data));
    this.updateAllComputedValue();
    return this.getState();
  }, true);

  setState = (data, isUnify) => {
    return isUnify ? this._setStateUnify(data) : this._setState(data);
  };

  _setStateTo = this._wrapStateResponder((data) => {
    this.data.setTo(this.removeKeyInComputedMap(data));
    this.updateAllComputedValue();
    return this.getState();
  })

  _setStateToUnify = this._wrapStateResponder((data) => {
    this.data.setTo(this.removeKeyInComputedMap(data));
    this.updateAllComputedValue();
    return this.getState();
  }, true);

  setStateTo = (data, isUnify) => {
    return isUnify ? this._setStateToUnify(data) : this._setStateTo(data);
  };

  setStateItemTo = (key, value, isUnify) => {
    return this.setState({
      [`${key}`]: value,
    }, isUnify);
  }

  setStateItem = (key, value, isUnify) => {
    return this.setStateItemTo(key, {
      ...ensureValidObject(this.getState()[key]),
      ...ensureValidObject(value),
    }, isUnify);
  }

  computed = this._wrapStateResponder((...rest) => {
    this.addComputed(...rest);
    this.updateAllComputedValue;
  })

  removeComputed = this._wrapStateResponder((key) => {
    delete this.computedMap[key];
    this.updateAllComputedValue();
  })

  watch = this.addWatcher

  removeAllWatcher = () => {
    this.watchers.splice(0, this.watchers.length);
  }

}

class ReactiveStateWithInitial extends ReactiveState {

  constructor(config) {
    super(config);

    this.initialData.set(this.data.get());
  }

  initialData = createNoCitationState()
  getInitialData = this.initialData.get
  setInitialData = this.initialData.set
  setInitialDataTo = this.initialData.setTo

  // state related
  setStateToInitial = (isUnify) => this.setStateTo(this.getInitialData(), isUnify)
  setStateInitial = (isUnify) => this.setState(this.getInitialData(), isUnify)
}

class ReactiveStateWithRESTfulAPI extends ReactiveStateWithInitial {

  constructor(config) {
    super(config);
    this.disableAllWatcher();
    config = ensureValidObject(config);

    // 初始化一些默认属性
    this.setInitialData({
      // 详情
      profile: {},
      form: {},
      isDetailLoaded: false, // 至少完成过一次加载
      isDetailLoading: false,

      // 列表
      resultSet: [],
      statistics: {},
      pagination: {},
      sort: {},
      filter: {},
      isListLoaded: false, // 至少完成过一次加载
      isListLoadingMore: false,
      isListReloading: false,
    });

    this.setInitialPagination({
      pageNumber: 1, // 当前页码
      pageSize: 20,
      totalPages: 1,
      totalRecords: 0,
      ...ensureValidObject(config.initialPagination),
    });

    this.setInitialSort({
      ...ensureValidObject(config.initialSort),
    });

    this.setInitialFilter({
      keyword: undefined,
      ...ensureValidObject(config.initialFilter),
    });

    // 初始化state
    this.setStateToInitial();
    this.initComputed({
      isListLoading: state => {
        return state.isListLoadingMore || state.isListReloading;
      },
      hasMore: state => {
        return state.isListLoaded && _.get(state, "pagination.totalPages") > _.get(state, "pagination.pageNumber");
      },
      noResult: state => {
        return state.isListLoaded && _.get(state, "pagination.totalRecords") === 0;
      },
      hasResult: state => {
        return state.isListLoaded && _.get(state, "pagination.totalRecords") > 0;
      },
      canLoadMore: state => {
        return state.hasMore && !state.isListLoading;
      },
      listFooterType: state => {
        const {
          isEmpty,
          isListLoaded,
          isListLoadingMore,
          canLoadMore,
        } = state;

        if (isEmpty) {
          return;
        }

        // 正在加载更多
        if (isListLoadingMore) {
          return "loading";
        }

        // 列表已加载过一次
        if (isListLoaded && !canLoadMore) {
          return "noMore";
        }
      },
      ...ensureValidObject(config.computed), // 兜底, 避免computed改成覆盖式后, 被这边覆盖
    });

    // 初始化请求参数
    const service = ensureValidObject(config.service);
    this.setListRequestParams({
      ...ensureValidObject(config.listRequestParams),
    });
    this.setDetailRequestParams({
      ...ensureValidObject(config.detailRequestParams),
    });
    this.reloadDetailRequester = singleRequester({ request: service.getSingle });
    this.reloadListRequester = singleRequester({ request: service.getList });
    this.loadMoreRequester = singleRequester({ request: service.getList });

    this.enableAllWatcher();
  }

  initialPagination = createNoCitationState()
  initialSort = createNoCitationState()
  initialFilter = createNoCitationState()
  listRequestParams = createNoCitationState()
  detailRequestParams = createNoCitationState()

  setInitialPagination = (...rest) => {
    this.initialPagination.set(...rest);
    this.setInitialData({
      pagination: this.initialPagination.get(),
    });
  }

  setInitialSort = (...rest) => {
    this.initialSort.set(...rest);
    this.setInitialData({
      sort: this.initialSort.get(),
    });
  }

  setInitialFilter = (...rest) => {
    this.initialFilter.set(...rest);
    this.setInitialData({
      filter: this.initialFilter.get(),
    });
  }

  setListRequestParams = this.listRequestParams.set

  setDetailRequestParams = this.detailRequestParams.set

  reloadDetail = (params) => {
    this.setState({ isDetailLoading: true });

    params = {
      ...this.detailRequestParams.get(),
      ...ensureValidObject(params),
    };

    return this.reloadDetailRequester.create(params).then(res => {
      if (res?.success === true) {
        this.setState({
          profile: ensureValidObject(res.data),
          form: ensureValidObject(res.data),
          isDetailLoading: false,
          isDetailLoaded: true,
        });
      }
      if (res?.success === false) {
        this.setState({ isDetailLoading: false });
      }

      return res;
    });
  }

  getCurrentListRequestParams = () => {
    const state = this.getState();

    return ({
      ...state.filter,
      ...state.sort,
      pageSize: state.pagination.pageSize,
      pageNumber: state.pagination.pageNumber,
    });
  }

  reloadList = (params) => {
    this.setState({
      isListReloading: true,
      isListLoadingMore: false,
    });
    this.loadMoreRequester.cancel();

    params = {
      ...this.getCurrentListRequestParams(),
      ...this.listRequestParams.get(),
      ...ensureValidObject(params),
      pageNumber: 1,
    };

    return this.reloadListRequester.create(params).then(res => {
      if (res?.success === true) {
        this.setState({
          resultSet: res.data.resultSet,
          statistics: res.data.statistics,
          pagination: {
            totalPages: res.data.totalPages,
            totalRecords: res.data.totalRecords,
            pageNumber: res.data.currentPage,
            pageSize: res.data.pageSize,
          },
          isListReloading: false,
          isListLoaded: true,
        });
      }
      if (res?.success === false) {
        this.setState({ isListReloading: false });
      }

      return res;
    });
  };

  loadMore = (params) => {
    const { canLoadMore } = this.getState();
    if (!canLoadMore) {
      return;
    }

    this.setState({ isListLoadingMore: true });

    params = {
      ...this.getCurrentListRequestParams(),
      ...this.listRequestParams.get(),
      ...ensureValidObject(params),
    };
    params.pageNumber += 1;

    return this.loadMoreRequester.create(params).then(res => {
      if (res?.success === true) {
        this.setState({
          resultSet: [
            ...this.getState().resultSet,
            ...res.data.resultSet,
          ],
          statistics: res.data.statistics,
          pagination: {
            totalPages: res.data.totalPages,
            totalRecords: res.data.totalRecords,
            pageNumber: res.data.currentPage,
            pageSize: res.data.pageSize,
          },
          isListLoadingMore: false,
        });
      }
      if (res?.success === false) {
        this.setState({ isListLoadingMore: false });
      }

      return res;
    });
  };

  // 以下为快捷方法, 可随意添加
  resetDetail = (isUnify) => {
    this.setState({
      profile: {},
      form: {},
      isDetailLoaded: false,
      isDetailLoading: false,
    }, isUnify);
    this.reloadDetailRequester.cancel();
    return this.getState();
  }
  resetList = (isUnify) => {
    this.setState({
      resultSet: [],
      statistics: {},
      pagination: this.initialPagination.get(),
      sort: this.initialSort.get(),
      filter: this.initialFilter.get(),
      isListLoaded: false,
      isListReloading: false,
      isListLoadingMore: false,
    }, isUnify);
    this.reloadDetailRequester.cancel();
    this.loadMoreRequester.cancel();
    return this.getState();
  }
  reset = (isUnify) => {
    this.setStateToInitial(isUnify);
    this.reloadDetailRequester.cancel();
    this.loadMoreRequester.cancel();
    return this.getState();
  }

  setProfile = (data, isUnify) => this.setStateItem("profile", data, isUnify)
  setForm = (data, isUnify) => this.setStateItem("form", data, isUnify);
  setFilter = (data, isUnify) => this.setStateItem("filter", data, isUnify)
  setSort = (data, isUnify) => this.setStateItem("sort", data, isUnify)
  setPagination = (data, isUnify) => this.setStateItem("pagination", data, isUnify)

  setProfileTo = (data, isUnify) => this.setStateItemTo("profile", data, isUnify)
  setFormTo = (data, isUnify) => this.setStateItemTo("form", data, isUnify)
  setFilterTo = (data, isUnify) => this.setStateItemTo("filter", data, isUnify)
  setSortTo = (data, isUnify) => this.setStateItemTo("sort", data, isUnify)
  setPaginationTo = (data, isUnify) => this.setStateItemTo("pagination", data, isUnify)

  setFilterToInitial = (isUnify) => this.setFilterTo(this.initialFilter.get(), isUnify);
  setSortToInitial = (isUnify) => this.setSortTo(this.initialSort.get(), isUnify);
  setPaginationToInitial = (isUnify) => this.setPaginationTo(this.initialPagination.get(), isUnify);
}

const _createReactiveModel = (
  ReactiveStateClass,
  reflectReducers,
  reflectEffects
) => {

  return model => {
    model = ensureValidObject(model);

    const reactiveState = new ReactiveStateClass({
      ...model,
      data: () => ensureValidObject(model.state),
      watch: null, // watch 在subscriptions里注册, 并注入dispatch、reactiveState参数
    });

    const handlerRequestErrorByDefaultInModel = ensureFunction(model.handlerRequestErrorByDefaultInModel);
    const service = ensureValidObject(model.service);

    return ({
      namespace: model.namespace,
      state: reactiveState.getState(),
      reducers: {
        setState(state, action) {
          reactiveState.setStateTo({
            ...reactiveState.getState(),
            ...ensureValidObject(action.payload),
          }, true);
          return reactiveState.getState();
        },
        ...ensureArray(reflectReducers).reduce((_reducers, name) => {
          _reducers[name] = (state, action) => {
            if (action.payload === undefined) {
              reactiveState[name](true);
            } else {
              reactiveState[name](action.payload, true);
            }
            return reactiveState.getState();
          };
          return _reducers;
        }, {}),
        ...Object.entries(ensureValidObject(model.reducers)).reduce((_reducers, [name, reducer]) => {
          _reducers[name] = (state, action) => {
            reactiveState.setStateTo(reducer(reactiveState.getState(), action), true);
            return reactiveState.getState();
          };
          return _reducers;
        }, {}),
        setStateSkipReactiveState(state, action) {
          return action.payload;
        },
      },
      effects: {
        // 反射到 reactiveState
        ...reflectEffects.reduce((_effects, methodName) => {
          _effects[methodName] = function* (action, __) {
            return yield reactiveState[methodName]?.(action.payload);
          };
          return _effects;
        }, {}),
        // 错误处理
        *getSingle({ payload }, { call, put, select }) {
          const res = yield call(service.getSingle, payload);

          // if (!res.success) {
          //   yield handlerRequestErrorByDefaultInModel(res, { call, put, select });
          // }
          return res;
        },
        *getList({ payload }, { call, put, select }) {
          const res = yield call(service.getList, payload);

          // if (!res.success) {
          //   yield handlerRequestErrorByDefaultInModel(res, { call, put, select });
          // }
          return res;
        },
        *reloadDetail(action, { call, put, select }) {
          const res = yield call(reactiveState.reloadDetail, action.payload);
          if (res?.success === false) {
            yield handlerRequestErrorByDefaultInModel(res, { call, put, select });
          }
          return res;
        },
        *reloadList(action, { call, put, select }) {
          const res = yield call(reactiveState.reloadList, action.payload);
          if (res?.success === false) {
            yield handlerRequestErrorByDefaultInModel(res, { call, put, select });
          }
          return res;
        },
        *loadMore(action, { call, put, select }) {
          const res = yield call(reactiveState.loadMore, action.payload);
          if (res?.success === false) {
            yield handlerRequestErrorByDefaultInModel(res, { call, put, select });
          }
          return res;
        },
        // 为 effect 注入 reactiveState
        ...Object.entries(ensureValidObject(model.effects)).reduce((_effects, [name, fn]) => {
          _effects[name] = function* (action, __) {
            return yield* fn(action, __, reactiveState);
          };
          return _effects;
        }, {}),
      },
      subscriptions: {
        ...ensureValidObject(model.subscriptions),
        _connectModelWithReactiveState({ dispatch, history }, onError) {
          reactiveState.watch("**", (state, prevState) => {
            dispatch({ type: "setStateSkipReactiveState", payload: state });
          });
          // 注入 dispatch 和 reactiveState
          ensureArray(model.watch).map(item => {
            const watcher = reactiveState.watch(item);
            const watcherOriginHandler = watcher.handler;
            watcher.handler = (...rest) => watcherOriginHandler(...rest, dispatch, reactiveState);
          });

          return () => {
            reactiveState.removeAllWatcher();
          };
        },
      },
    });
  };
};

const reflectMethods_base = {
  reducers: [

  ],
  effects: [
    "computed",
    "watch",
  ],
};
const reflectMethods_withInitial = {
  reducers: [
    ...reflectMethods_base.reducers,
    "setStateToInitial",
    "setStateInitial",
  ],
  effects: [
    ...reflectMethods_base.effects,
    "setInitialData",
    "setInitialDataTo",
  ],
};

const reflectMethods_RESTfulAPI = {
  reducers: [
    ...reflectMethods_withInitial.reducers,
    "resetDetail",
    "resetList",
    "reset",

    "setProfile",
    "setForm",
    "setFilter",
    "setSort",
    "setPagination",

    "setProfileTo",
    "setFormTo",
    "setFilterTo",
    "setSortTo",
    "setPaginationTo",

    "setFilterToInitial",
    "setSortToInitial",
    "setPaginationToInitial",
  ],
  effects: [
    ...reflectMethods_withInitial.effects,
    "setInitialPagination",
    "setInitialSort",
    "setInitialFilter",
    "setListRequestParams",
    "setDetailRequestParams",
    "reloadDetail",
    "reloadList",
    "loadMore",
  ],
};

const createReactiveModel = _createReactiveModel(
  ReactiveState,
  reflectMethods_base.reducers,
  reflectMethods_base.effects
);

const createReactiveModelWithInitial = _createReactiveModel(
  ReactiveStateWithInitial,
  reflectMethods_withInitial.reducers,
  reflectMethods_withInitial.effects
);

const createReactiveModelWithRESTfulAPI = _createReactiveModel(
  ReactiveStateWithRESTfulAPI,
  reflectMethods_RESTfulAPI.reducers,
  reflectMethods_RESTfulAPI.effects
);

export {
  ReactiveState,
  ReactiveStateWithInitial,
  ReactiveStateWithRESTfulAPI,
  createReactiveModel,
  createReactiveModelWithInitial,
  createReactiveModelWithRESTfulAPI,
};
