import * as shippingServiceProviderService from '../services/shippingServiceProvider';
import { handleRequestError } from '@utils';
import { createReactiveModelWithRESTfulAPI } from '@utils/reactiveState';

export default createReactiveModelWithRESTfulAPI({
  namespace: 'shippingServiceProvider',
  service: shippingServiceProviderService,
  handlerRequestErrorByDefaultInModel: res => handleRequestError(res),
  state: {

  },
  computed: {

  },
  reducers: {

  },
  effects: {
    *getSingle({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.getSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *patchSingle({ payload }, { call, put, select }) {
      const res = yield call(shippingServiceProviderService.patchSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
  },
});
