import { createAction, handleRequestErrorInModel } from '../../utils';
import {factory, listTypes} from './mixins/RESTfulBaseModel';
import * as currencyService from '../../services/currency';
import _ from 'lodash';

export default {
  namespace: 'currency',
  state: {
    isShopRelatedModel: true,
    list: [],
    currentISOCode: 'EUR',
  },
  reducers: {
    resetList(state, { payload }) {
      return {
        ...state,
        list: [],
      };
    },
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    setList(state, { payload }) {
      const currencyList = [
        _.find(payload, c => c.isoCode == state.currentISOCode),// 当前使用货币
        ..._.remove(payload, c => c.isoCode != state.currentISOCode),
      ];
      return {
        ...state,
        list: currencyList,
      };
    },
  },
  effects: {
    *reloadList({payload}, { call, put, select }) {
      const res = yield call(currencyService.getList, payload);
      if (res.success) {
        yield put({ type: 'setList', payload: res.data });
      }
      return res;
    },
  },
};
