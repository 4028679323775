import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { Teaset } from 'ui-m/rn';
const { Overlay } = Teaset;
import { Icon } from 'ui-m/rn';
import resources from '../config/resources';

let timer;
let lastOverlayViewKey;

const Toast = {
  show(options = {}){
    // Arguments
    let {
      text = '',
      icon = null,
      // position = 'center',
      ...rest
    } = options;
    let duration = getToastDurationTime(text);
    if (icon === 'success') {
      icon = <Icon icon="toastSucceed"/>;
    } else if (icon === 'fail') {
      icon = <Icon icon="toastFailed"/>;
    }
    // Style type
    let type;
    if ( text && icon ) {
      type = 'iconAndText';
    } else if ( text ) {
      type = 'onlyText';
    } else if ( icon ) {
      type = 'onlyIcon';
    }
    // Render
    const overlayView = (
      <Overlay.PopView
        style={styles.overlay}
        modal
        overlayOpacity={0}
        overlayPointerEvents="none"
        closeOnHardwareBackPress={false}
      >
        <View style={[styles.whitePanel, styles[`whitePanel_${type}`] ]}>
          {icon ? <View style={styles.iconWrapper}>{icon}</View> : null}
          {text ? <Text style={styles.text}>{text}</Text> : null}
        </View>
      </Overlay.PopView>
    );
    // Hide previours and show current one
    Toast.hide(lastOverlayViewKey);
    const key = Overlay.show(overlayView);
    lastOverlayViewKey = key;
    // Set timer to hide
    clearTimeout(timer);
    timer = setTimeout(() => {
      Toast.hide(key);
    }, duration);
    // return key for hide()
    return key;
  },
  hide(key){
    if ( key ) {
      Overlay.hide(key);
    }
  },
};

// for Toast() usage
const expots = function def(textOrOptions, options = {}){
  const _options = typeof textOrOptions === 'string' ? {text: textOrOptions} : textOrOptions;
  return Toast.show({
    ..._options,
    ...options,
  });
};

// for Toast.show(), Toast.hide() usage
expots.show = expots;
expots.hide = Toast.hide;

// for Toast.success() usage
expots.success = function success(textOrOptions){
  let haveText = typeof textOrOptions === 'string';
  const options = haveText ? {text: textOrOptions} : {
    duration: 1300,
    ...textOrOptions,
  };

  return Toast.show({
    icon: 'success',
    ...options,
  });
};

// for Toast.fail() usage
expots.fail = function fail(textOrOptions){
  const options = typeof textOrOptions === 'string' ? {text: textOrOptions} : textOrOptions;
  return Toast.show({
    icon: 'fail',
    ...options,
  });
};

export default expots;

const getToastDurationTime = (text = '') => {
  let haveChinese = /.*[\u4e00-\u9fa5]+.*$/.test(text);
  let length = text.length;
  let duration = 2000;
  let wordsNum = text.split(' ').filter(i=>i).length;

  if (haveChinese) {
    if (length > 15) {
      duration = 4000;
    } else if (length > 10) {
      duration = 3000;
    }
  } else {
    if (wordsNum > 9) {
      duration = 4000;
    } else if (wordsNum > 6) {
      duration = 3000;
    }
  }

  return duration;
};

const styles = StyleSheet.create({
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0,0,0,0.1)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  whitePanel: {
    borderRadius: 8,
    backgroundColor: 'rgba(0,0,0,0.8)',
    marginBottom: 30,
    // marginBottom: 80,
    justifyContent: 'center',
    alignItems: 'center',
    // maxWidth: '70%', // !! Web 不支持，换成左右 Margin 留白 + 固定 maxWidth 方案
    marginHorizontal: 20,
    maxWidth: 320,
  },
  whitePanel_iconAndText: {
    paddingTop: 11,
    paddingBottom: 10,
    paddingHorizontal: 18,
    minWidth: 100,
  },
  whitePanel_onlyText: {
    paddingTop: 11,
    paddingBottom: 13,
    paddingHorizontal: 18,
  },
  whitePanel_onlyIcon: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingHorizontal: 6,
  },
  iconWrapper: {
    height: 44,
    width: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    paddingTop: 3,
    fontSize: 14,
    lineHeight: 20,
    textAlign: "center",
    color: "#fff",
  },
});





