// 用于跨作用域传递 store，并避免循环依赖（dva 依赖 model，导致在 model 依赖到的文件中无法获取 store）

let _store, _storeReadyResolve;

const _storeReadyPromise = new Promise(resolve=>{
  _storeReadyResolve = resolve;
});

function setStore(store){
  _storeReadyResolve(_store = store);
}

async function getStore(){
  if (_store) {
    return _store;
  }
  return _storeReadyPromise.then(()=>{
    return _store;
  });
}

async function select(getter = ()=>{}){
  return getter((await getStore()).getState());
}

export {
  setStore,
  getStore,
  select,
};
