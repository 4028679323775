import React from 'react';
import { StyleSheet, View, Animated, Easing, Text } from 'react-native';
import { Teaset } from 'ui-m/rn';
const { Overlay } = Teaset;
import resources from '../config/resources';
import propTypes from 'prop-types';

export class LoadingBox extends React.Component {
  static propTypes = {
    tip: propTypes.string,
  }

  static defaultProps = {
    tip: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      rotateValue: new Animated.Value(0),
    };
  }

  componentDidMount(){
    this.startAnimation();
  }

  componentWillUnmount(){
    // this.startAnimation()
  }

  startAnimation() {
    this.state.rotateValue.setValue(0);
    Animated.timing(this.state.rotateValue, {
      toValue: 1,
      duration: 700,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => this.startAnimation());
  }

  render(){
    const {tip = ''} = this.props;
    return (
      <View style={[styles.spinnerContainer, this.props.style]}>
        <Animated.Image style={[styles.spinner, {
          transform: [{
            rotate: this.state.rotateValue.interpolate({
              inputRange: [0, 1],
              outputRange: ['0deg', '360deg'],
            }),
          }],
        }]} source={resources.spinnerIcon2} />
        {
          !tip ? null :
            <Text style={styles.spinner_text}>{tip}</Text>
        }
      </View>
    );
  }
}


const ScreenLoading = {
  loadingKeys: [],
  _overlayKey: null,
  renderLoadingView() {
    return (
      <Overlay.PopView
        style={styles.loadingMask}
        modal
        overlayOpacity={0}
      >
        <LoadingBox />
      </Overlay.PopView>
    );
  },
  show(loadingKey){
    if (!this._overlayKey) {
      this._overlayKey = Overlay.show(this.renderLoadingView());
    }
    if (loadingKey) {
      this.loadingKeys.push(loadingKey);
    }
  },
  hide(loadingKey){
    if (loadingKey) {
      this.loadingKeys = this.loadingKeys.filter(i => i !== loadingKey);
    }
    if (!this.loadingKeys.length && this._overlayKey) {
      Overlay.hide(this._overlayKey);
      this._overlayKey = null;
    }
  },
};

export default ScreenLoading;

const styles = StyleSheet.create({
  loadingMask: {
    zIndex: 999,
    backgroundColor: 'rgba(0,0,0,0.1)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  spinnerContainer: {
    paddingVertical: 21,
    paddingHorizontal: 21,
    borderRadius: 12,
    backgroundColor: 'rgba(0,0,0,0.8)',
    marginBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    width: 43,
    height: 43,
    borderRadius: 20,
    overflow: 'hidden',
    transform: [{
      rotate: '30deg',
    }],
  },
  spinner_text: {
    marginTop: 18,
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 19,
    color: '#FFFFFF',
    letterSpacing: 0.45,
    textAlign: 'center',
    flexShrink: 1,
  },
});
