import React from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import { resources } from "../config";
import { Theme } from "ui-m/rn";
import { useSelector } from "react-redux";

const Empty = props => {
  const isPhone = Theme.isPhoneSizedWindow;
  const i18n = useSelector(state => state.i18n);

  const { containerStyle, iconStyle, textStyle } = props;

  const icon = props.icon || resources.listEmpty;
  const text = props.text || i18n[props.i18nKey] || i18n.map["cmn.no_more_content"];

  return (
    <View
      style={[
        styles.wrap,
        isPhone ? styles.phoneWrap : styles.pcWrap,
        containerStyle,
      ]}
    >
      <Image
        source={{ uri: icon }}
        style={[
          styles.icon,
          isPhone ? styles.phoneIcon : styles.pcIcon,
          iconStyle,
        ]}
      />
      {
        !!text &&
        <Text
          style={[
            styles.text,
            isPhone ? styles.phoneText : styles.pcText,
            textStyle,
          ]}
        >
          {text}
        </Text>
      }
    </View>
  );
};

// const
const styles = StyleSheet.create({
  wrap: {
    alignItems: "center",
  },
  phoneWrap: {
    maxWidth: 335,
  },
  pcWrap: {
    maxWidth: 440,
  },
  icon: {

  },
  phoneIcon: {
    width: 100,
    height: 100,
  },
  pcIcon: {
    width: 80,
    height: 80,
  },
  text: {
    textAlign: "center",
  },
  phoneText: {
    color: "#666",
    fontSize: 16,
    lineHeight: 20,
    marginTop: 30,
  },
  pcText: {
    fontSize: 15,
    lineHeight: 20,
    color: "rgba(51,51,51,0.6)",
    marginTop: 28,
  },
});

const emptyType = [
  {
    type: "Lock",
    icon: resources.lockEmpty,
  },
];

emptyType.forEach(i => {
  Empty[i.type] = props => <Empty icon={i.icon} text={i.text} i18nKey={i.i18nKey} {...props} />;
});

export default Empty;
