import qs from 'qs';
// import { isDuoKe } from 'src/common/helpers/env'
const isDuoKe = true;
// import { waitMs } from 'utils'
import { delay as waitMs } from 'crow/utils/async';

const ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const ID_LENGTH = 8;
const isDev = process.env.NODE_ENV !== 'production';
const debugWarn = (tag, txt) => {
  if (isDev) {
    console.warn(`[${tag}] %c${txt}`, 'background-color: red; color: #fff; padding: 4px 8px;');
  }
};

function generateID() {
  let rtn = '';
  for (let i = 0; i < ID_LENGTH; i += 1) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }
  return rtn;
}

const EventName = {
  NATIVE_ASYNC: 'native_async',
};

const NativeFuncs = {
  NAVIGATION: {
    CUSTOMER_SERVICE: { // 跳转到人工客服
      ns: 'navigation',
      fn: 'customerService',
    },
    GENERAL: {
      ns: 'navigation',
      fn: 'general',
    },
    ASSOCIATION_ORDER: { // 预订（售）单跳转到关联销售（进货）单的特殊协议
      ns: 'navigation',
      fn: 'associationOrder',
    },
  },
  INVENTORY: {
    INVENTORY_INFO: {
      ns: 'inventory',
      fn: 'inventoryInfo',
    },
    UNINVENTORY_INFO: {
      ns: 'inventory',
      fn: 'uninventoryInfo',
    },
    SEND_SUMMARY_SWITCH: {
      ns: 'inventory',
      fn: 'sendSummarySwitch',
    },
    SEND_INVENTORY_INFO: {
      ns: 'inventory',
      fn: 'sendInventoryInfo',
    },
    SEND_UNINVENTORY_INFO: {
      ns: 'inventory',
      fn: 'sendUninventoryInfo',
    },
  },
  SYSTEM: {
    APP_SHARE: {
      ns: 'system',
      fn: 'appShare',
    },
    USER_DATA: {
      ns: 'system',
      fn: 'userData',
    },
    IMAGE_BROWSER: {
      ns: 'system',
      fn: 'Imagebrowser',
    },
    ALERT: {
      ns: 'system',
      fn: 'alert',
    },
    WEBVIEW_ACTION: {
      ns: 'system',
      fn: 'webViewAction',
    },
    ENTRY_PARAM: {
      ns: 'system',
      fn: 'generalData',
    },
    REGISTER_CALLBACK: {
      ns: 'system',
      fn: 'registerCB',
    },
    SCAN_CODE: {
      ns: 'system',
      fn: 'scanCode',
    },
    STATUS_BAR_STYLE: {
      ns: 'system',
      fn: 'statusBarStyle',
    },
    CLOSE: {
      ns: 'system',
      fn: 'close',
    },
    BACK_BTN_CB: {
      ns: 'system',
      fn: 'backBtnCallback',
    },
    SAVE_IMAGE: {
      ns: 'system',
      fn: 'saveImage',
    },
    PAYMENT: {
      ns: 'system',
      fn: 'payment',
    },
    PAY: {
      ns: 'system',
      fn: 'pay',
    },
    VIEW_PURCHASE_ORDER: {
      ns: 'system',
      fn: 'viewPurchaseOrder',
    },
    GOTO_BATCH_EDIT: {
      ns: 'system',
      fn: 'goToBatchEdit',
    },
    ORDER_DETAIL: {
      ns: 'system',
      fn: 'orderDetail',
    },
    PROFITS_VIEW: {
      ns: 'system',
      fn: 'profitsView',
    },
    LOADING: {
      ns: 'system',
      fn: 'loading',
    },
    OPEN_WIDGET: {
      ns: 'system',
      fn: 'openWidget',
    },
    EVENT_TRACK: {
      ns: 'system',
      fn: 'eventTrack',
    },
    OPEN_WINDOW: {
      ns: 'system',
      fn: 'openWindow',
    },
    UPDATE_APP: {
      ns: 'system',
      fn: 'updateApp',
    },
    INIT_PAGE: {
      ns: 'system',
      fn: 'initPage',
    },
    SAVE_PICTURE: {
      ns: 'system',
      fn: 'savePicture',
    },
    PREVIEW_PICTURE: {
      ns: 'system',
      fn: 'previewPicture',
    },
    VIEW_SHIP_ORDER: {
      ns: 'system',
      fn: 'viewShipOrder',
    },
    GET_LOCAL_DEFAULT: {
      // 获取原生客户端的默认配置
      ns: 'system',
      fn: 'getLocalDefault',
    },
    RECOVERY_PENDING_ORDER: {
      ns: 'system',
      fn: 'recoveryPendingOrder',
    },
    HOME_PAGE: {
      ns: 'system',
      fn: 'homePage',
    },
    GET_DATA_CENTER_INFO: {
      // 获取flutter端的dataCenter信息
      ns: 'system',
      fn: 'getDataCenterInfo',
    },
    SET_DATA_CENTER_INFO: {
      // 设置flutter端的dataCenter信息
      ns: 'system',
      fn: 'setDataCenterInfo',
    },
  },
  BHB: {
    INIT_BUHUOBAO_CATE_SELECTER: {
      ns: 'buhuobao',
      fn: 'initBuhuobaoCateSelecter',
    },
    CREATE_CATE: {
      ns: 'buhuobao',
      fn: 'creatCate',
    },
    NEW_CATE_DATA: {
      ns: 'buhuobao',
      fn: 'newCateData',
    },
    DID_SELECT_CATE: {
      ns: 'buhuobao',
      fn: 'didSelectCate',
    },
    GO_BUY_BUHUOBAO: {
      ns: 'buhuobao',
      fn: 'goBuyBuhuobao',
    },
    JUMP_TO_MINI_PROGRAM: {
      ns: 'mini',
      fn: 'jumpToMiniProgram',
    },
  },
  ALERT: {
    SHARE: {
      ns: 'alert',
      fn: 'share',
    },
  },
  // 报表
  REPORT: {
    DATA: {
      ns: 'report',
      fn: 'data',
    },
    SEND_DATA: {
      ns: 'report',
      fn: 'sendData',
    },
    LANDSCAPE: {
      ns: 'report',
      fn: 'landscape',
    },
    TOOLBAR: {
      ns: 'report',
      fn: 'toolbar',
    },
    ENTRANCE: {
      ns: 'report',
      fn: 'entrance',
    },
  },
};


const registeredFunc = {};
window.nativeCall = (fName, params) => {
  debugWarn('gmAppSDK', `回调事件被触发: fName: ${fName}: \n ${JSON.stringify(params)}`);
  if (registeredFunc[fName]) {
    registeredFunc[fName](params);
  } else {
    alert(`error native call:${fName}`);
  }
};

const callbackResolves = {};
window.nativeCallback = function nativeCallback(uid, ret) {
  if (uid in callbackResolves) {
    debugWarn('gmAppSDK', `异步事件被响应: uid: ${uid}`);
    document.body.removeChild(callbackResolves[uid].node);
    callbackResolves[uid].resolve(ret);
  } else {
    const iframeCallback = document.getElementsByTagName('iframe')?.[0]?.contentWindow?.nativeCallback;
    if (iframeCallback) {
      iframeCallback(uid, ret);
    } else {
      alert(`uid miss ${uid}`);
      console.error(`uid miss ${uid}`);
    }
  }
};

const windowDispatchEvent = (eName, payload) => {
  console.log("🚀 ~ eName:", eName);
  const ev = new window.CustomEvent(eName, { detail: payload });
  window.dispatchEvent(ev);
};

/**
 * web与宿主异步通信
 * @param f {Object} 包含命名空间和函数名, 例如：{ns: "NativeInfo",fn: "Version"}
 * @param param {String|Object} 函数参数
 * */
const callNativeAsyncFunc = (f, param = '') => {
  return new Promise((resolve) => {
    const uid = generateID();
    const mIframe = document.createElement('iframe');
    callbackResolves[uid] = { resolve, node: mIframe };
    const prm = typeof param === 'object' ? JSON.stringify(param) : param;
    const src = `native-asyn::${f.ns}::${f.fn}::${uid}::${prm}`;
    debugWarn('gmAppSDK', `发起异步事件${src}`);
    mIframe.src = src;
    mIframe.name = 'native_request';
    // 设置样式
    mIframe.style.display = 'none';
    mIframe.style.width = '0';
    mIframe.style.height = '0';
    document.body.appendChild(mIframe);
    windowDispatchEvent(EventName.NATIVE_ASYNC, {
      ...param,
      src,
      uid,
      ns: f.ns,
      fn: f.fn,
    });
  });
};

/**
 * 注册宿主调用回调函数
 * @param f {Object} 包含命名空间和函数名, 例如：{ns: "NativeInfo",fn: "Version"}
 * @param callback {Function} 回调函数
 * */
const registerNativeCall = (f, callback) => {
  const fName = `${f.ns}::${f.fn}`;
  debugWarn('gmAppSDK', `注册回调函数: fName: ${fName}`);
  registeredFunc[fName] = callback;
  callNativeAsyncFunc(NativeFuncs.SYSTEM.REGISTER_CALLBACK, {
    ns: f.ns,
    fn: f.fn,
  });
};

const olderNative = (props) => {
  const mIframe = document.createElement('iframe');
  const src = `obj-c::${props.map((v) => encodeURI(v)).join('::')}`;
  mIframe.src = src;
  mIframe.name = 'older_native_request';
  mIframe.style.display = 'none';
  mIframe.style.width = '0';
  mIframe.style.height = '0';
  document.body.appendChild(mIframe);
};

// todo deprecated
const nativeInteract = (props) => {
  const { env, win } = props;
  // eslint-disable-next-line no-underscore-dangle
  win.__VERSION__ = env.VERSION;

  const search = `${win.location.search.slice(1)}&${win.location.hash.split('?')[1] || ''}`;
  const appInfo = qs.parse(search);
  const getAppInfo = async () => {
    const ret =
      (await callNativeAsyncFunc({
        ns: 'system',
        fn: 'userData',
      })) || {};
    const appKey = ret.key || ret.token || appInfo.key;
    const projectId = ret.projId || ret.projectId || ret.app_pid || appInfo.projId;
    const appVersion = ret.app_version || ret.appVersion || appInfo.appVersion;
    const masksToken = ret.masksToken || ret.mask_token;
    const userId = ret.userId || ret.staff_id;
    const lang = ret.lang || ret.language_key || appInfo.lang;
    Object.assign(appInfo, ret, { key: appKey, appVersion, masksToken, userId, projectId, lang });
    return appInfo;
  };
  getAppInfo();

  const updateAppInfo = async () => {
    if (isDuoKe) {
      await getAppInfo();
    } else {
      await waitMs(200);
    }
  };

  return {
    olderNative,
    registerNativeCall,
    callNativeAsyncFunc,
    NativeFuncs,
    appInfo,
    getAppInfo,
    updateAppInfo,
  };
};

export {
  registerNativeCall,
  callNativeAsyncFunc,
  NativeFuncs,
  nativeInteract,
};
