import { request } from "../utils";
import { getCompanyId } from "@globalUtils";
import qs from "qs";

export const getList = (params = {}) => request(`/owners/${getCompanyId()}/shippingProviders?${qs.stringify(params)}`);

export const getSingle = ({ id }) => request(`/owners/${getCompanyId()}/shippingProviders/${id}`);

export const patchSingle = ({ id, ...rest }) => request(`/owners/${getCompanyId()}/shippingProviders/${id}`, {
  method: "PATCH",
  data: rest,
});

export const getShippingMethods = () => request(`/owners/${getCompanyId()}/shippingMethods`);
