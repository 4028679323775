import * as dashboardServices from '../services/dashboard';
import * as daService from '../services/dataAnalyses';
import { handleRequestError, removeSearchKeys, Storage, lazyFunction } from '../utils';

const lastViewDownstreamIdStorageKey = "lastViewDownstreamId";

const canViewTargetDashboard = (dashboardId, streamType, permission = {}) => {
  // 对账报表
  if (dashboardId === 4) {
    const {
      showSupplierDebt,
      showClientDebt,
    } = permission;

    if (
      (streamType === "up" && !showSupplierDebt) ||
      (streamType === "down" && !showClientDebt)
    ) {
      return false;
    }
  }

  return true;
};

const defaultState = {
  isInitialized: false,

  refreshPanelEveryChange: 0,
  isLoadingDownstreams: false,
  isLoadedDownstreams: false,
  isLoadingDashboards: false,

  currentStreamType: "down", // up | down
  downstreams: [], // 自己可以看的下游
  currentDownstream: {},

  // upstreams: dashboardSupplier.list.resultSet, 黑端的所有供货商
  currentUpstream: {},

  // dashboardViewers: dashboardViewer.list.result, 已授权看自己报表的黑端的供货商

  dashboardsBackup: [],
  dashboards: [],
  currentDashboard: {},

  permission: {},
};

export default {
  namespace: 'dashboard',
  state: {
    ...defaultState,
  },
  reducers: {
    resetState(statem, { payload = {} }) {
      return { ...defaultState, ...payload };
    },
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    refreshPanels(state, { payload }) {
      return {
        ...state,
        refreshPanelEveryChange: Date.now(),
      };
    },
    clearCurrentPreview(state, { payload }) {
      return {
        ...state,
        currentUpstream: {},
        currentDownstream: {},
        currentDashboard: {},
      };
    },
  },
  effects: {
    *init({ payload = {} }, { call, put, select, all }) {
      const { smartMode = true } = payload;

      let currentStreamType;
      let upstreamId; // 实际为 supplierId
      let downstreamId;
      let dashboardId;
      // 直接进入到报表, 根据url参数初始化
      if (smartMode) {
        const { up, view, report } = yield select(states => states.intention.URLParams);
        removeSearchKeys(["up", "view", "report"]);
        currentStreamType = +up ? "up" : "down";
        upstreamId = +up ? view : null;
        downstreamId = +up ? null : view;
        dashboardId = report;
      }
      // 从 MC 进入报表, 一般需要进入到指定报表
      else {
        currentStreamType = payload.currentStreamType;
        upstreamId = payload.upstreamId;
        downstreamId = payload.downstreamId;
        dashboardId = payload.dashboardId;
      }

      // 未指定下游, 则看最后看过的一家下游
      if (!downstreamId) {
        downstreamId = yield Storage.get(lastViewDownstreamIdStorageKey);
      }

      const { permission, dashboards } = yield select(state => state.dashboard);
      const { allowViewUpstreams, allowViewDownstreams } = permission;
      // 不能看上游, 则看下游
      // 不能看上游也不能看下游, 则看下游
      if (!allowViewUpstreams) {
        currentStreamType = "down";
        upstreamId = null;
      }
      // 能看上游不能看下游, 则看上游
      if (allowViewUpstreams && !allowViewDownstreams) {
        currentStreamType = "up";
        downstreamId = null;
      }
      // 确保对应的报表有权限看
      if (!canViewTargetDashboard(dashboardId, currentStreamType, permission)) {
        dashboardId = dashboards[0]?.id;
      }

      yield all([
        put({ type: "setState", payload: { currentStreamType } }),
        put({ type: "updateDashboards", payload: { id: +dashboardId || undefined } }),
        put({ type: "initCurrentUpstream", payload: { id: +upstreamId || undefined } }),
        put({ type: "initCurrentDownstream", payload: { id: +downstreamId || undefined } }),
      ]);

      yield put({ type: "setState", payload: { isInitialized: true } });

      return;
    },

    *initCurrentUpstream({ payload = {} }, { call, put, select, all }) {
      // dashboardSupplier是分页的
      // 如果指定的id, 则需要单独去查询
      const [res] = yield yield all([
        payload.id && put({ type: "dashboardSupplier/getSingle", payload: +payload.id }),
        put({ type: "updateUpstreams" }),
      ]);

      let upstream;
      if (res?.success) {
        upstream = res.data;
      }

      // 指定的上游可能不存在, 用列表中的第一个兜底
      if (!upstream) {
        const suppliers = yield select(states => states.dashboardSupplier.list.resultSet);
        upstream = suppliers[0];
      }

      if (upstream) {
        yield put({ type: "setCurrentUpstream", payload: upstream });
      }

      return;
    },

    *initCurrentDownstream({ payload = {} }, { call, put, select }) {
      yield yield put({ type: "updateDownstreams" });
      const downstreams = yield select(states => states.dashboard.downstreams);
      const downstream = downstreams.find(i => i.id === payload.id) || downstreams[0];
      if (downstream) {
        yield put({ type: "setCurrentDownstream", payload: downstream });
      }
    },

    *changeCurrentStreamType({ payload }, { call, put, select }) {
      const {
        dashboardsBackup,
        currentDashboard,
        permission,
        permission: {
          allowViewUpstreams,
          allowViewDownstreams,
        },
      } = yield select(state => state.dashboard);

      const streamTypes = [
        allowViewUpstreams && "up",
        allowViewDownstreams && "down",
      ].filter(i => i);

      if (!streamTypes.includes(payload)) {
        return;
      }

      // 还需要更新 dashboards, currentDashboard, menu(左侧菜单, 选中项)
      // 逻辑类似 updateDashboards
      const validDashboards = dashboardsBackup.filter(i => canViewTargetDashboard(i.id, payload, permission));
      yield put({
        type: "menu/setDashboardMenu",
        payload: validDashboards.map(i => ({ labelI18nKey: i.i18nKey, data: i })),
      });
      const _currentDashboard = (
        validDashboards.find(i => i.id === currentDashboard.id) || // 更新当前选择的报表
        validDashboards[0] // 用列表中的第一个报表
      );
      yield put({ type: "menu/setState", payload: { selectedKeys: [_currentDashboard.i18nKey] } });
      yield put({
        type: "setState",
        payload: {
          dashboards: validDashboards,
          currentDashboard: _currentDashboard,
          currentStreamType: payload,
        },
      });
    },

    *setCurrentUpstream({ payload }, { call, put, select }) {
      yield put({ type: "setState", payload: { currentUpstream: payload } });

      const res = yield call(dashboardServices.getMSCompanyInfo);
      if (res.success) {
        const currentUpstream = yield select(states => states.dashboard.currentUpstream);
        yield put({
          type: "setState",
          payload: {
            currentUpstream: { ...currentUpstream, ...res.data },
          },
        });
      } else {
        handleRequestError(res);
      }

      return;
    },

    *setCurrentDownstream({ payload }, { call, put, select }) {
      yield put({ type: "setState", payload: { currentDownstream: payload } });
      Storage.set(lastViewDownstreamIdStorageKey, payload.id);
      payload.id && daService.create({ type: "dashboard_viewed", remark: payload.id }); // 打点 最后浏览时间

      // 切换当前下游, 需要获取该店铺的其他数据(货币等)
      const res = yield call(dashboardServices.getMSCompanyInfo, payload.companyId);
      if (res.success) {
        const currentDownstream = yield select(states => states.dashboard.currentDownstream);
        if (currentDownstream.id === payload.id) {
          yield put({
            type: "setState",
            payload: {
              currentDownstream: {
                ...currentDownstream, ...res.data,
              },
            },
          });
        }
      } else {
        handleRequestError(res);
      }

      return;
    },

    *updateUpstreams({ payload }, { call, put, select }) {
      // 鉴权
      const permission = yield select(state => state.dashboard.permission);
      if (!permission.allowViewUpstreams) {
        return;
      }

      yield yield put({ type: "dashboardSupplier/reloadList" });
    },

    *updateDownstreams({ payload }, { call, put, select }) {
      // 鉴权
      const permission = yield select(state => state.dashboard.permission);
      if (!permission.allowViewDownstreams) {
        return;
      }

      yield put({ type: "setState", payload: { isLoadingDownstreams: true } });
      const res = yield call(dashboardServices.getDownstreams, payload);
      yield put({ type: "setState", payload: { isLoadingDownstreams: false } });

      if (res.success) {
        yield put({
          type: "setState",
          payload: {
            downstreams: res.data.dashboardViewers,
            isLoadedDownstreams: true,
          },
        });
      } else {
        handleRequestError(res);
        if (+res.status === 403) {
          yield put({
            type: "setState",
            payload: {
              downstreams: [],
              isLoadedDownstreams: true,
              permission: {
                ...permission,
                allowViewDownstreams: false,
              },
            },
          });
        }
      }

      return res;
    },

    *updateDashboards({ payload = {} }, { call, put, select }) {
      yield put({ type: "setState", payload: { isLoadingDashboards: true } });
      yield put({
        type: "menu/setDashboardMenu",
        payload: [{ labelI18nKey: 'mch.toast.loading', disabled: true }],
      });
      const res = yield call(dashboardServices.getDashboards);
      yield put({ type: "setState", payload: { isLoadingDashboards: false } });

      if (res.success) {
        const { currentStreamType, currentDashboard, permission } = yield select(states => states.dashboard);
        const validDashboards = res.data.dashboards.filter(i => canViewTargetDashboard(i.id, currentStreamType, permission));
        yield put({
          type: "menu/setDashboardMenu",
          payload: validDashboards.map(i => ({ labelI18nKey: i.i18nKey, data: i })),
        });
        const _currentDashboard = (
          validDashboards.find(i => i.id === payload.id) || // 优先设置为指定报表
          validDashboards.find(i => i.id === currentDashboard.id) || // 更新当前选择的报表
          validDashboards[0] // 用列表中的第一个报表
        );
        yield put({ type: "menu/setState", payload: { selectedKeys: [_currentDashboard.i18nKey] } });
        yield put({
          type: "setState",
          payload: {
            dashboardsBackup: res.data.dashboards,
            dashboards: validDashboards,
            currentDashboard: _currentDashboard,
          },
        });
      } else {
        handleRequestError(res);
      }

      return res;
    },

    *switchDashboard({ payload }, { call, put, select }) {
      const dashboards = yield select(states => states.dashboard.dashboards);
      const currentDashboard = dashboards.find(i => i.id === payload?.id) || payload; // 兜底用 payload
      if (currentDashboard) {
        yield put({ type: "menu/setState", payload: { selectedKeys: [payload?.i18nKey] } });
        yield put({ type: "setState", payload: { currentDashboard } });
      }
      return;
    },

    *queryDataSource({ payload }, { call, put, select }) {
      const res = yield call(dashboardServices.queryDataSource, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },

    *handleActivateDashboard({ payload }, { call, put, select, take, fork }) {
      yield fork(function* () {
        yield take('auth/lifeCycle_authEnsureValid');
        lazyFunction("activateDashboard", payload);
      });
      return;
    },

    *activateDashboard({ payload }, { call, put, select }) {
      const res = yield call(dashboardServices.activateDashboard, payload);

      return res;
    },

    *exportAsExcel({ payload }, { call, put, select }) {
      const res = yield call(dashboardServices.exportAsExcel, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    watchSwitchLang: [
      function* watch({ take, put, call, race, select }) {
        while (true) {
          yield take("i18n/switchLang/@@end");
          // 可能仍会触发
          const isInitialized = yield select(states => states.dashboard.isInitialized);
          if (isInitialized) {
            yield yield put({ type: "updateDashboards" });

            const { dashboards, currentDashboard } = yield select(states => states.dashboard);
            const newCurrentDashboard = dashboards.find(i => i.i18nKey === currentDashboard.i18nKey);

            if (newCurrentDashboard) {
              yield put({
                type: "setState",
                payload: {
                  currentDashboard: newCurrentDashboard,
                  refreshPanelEveryChange: Date.now(),
                },
              });
            }
          }
        }
      }, { type: 'watcher' },
    ],
    watchLogout: [
      function* watch({ take, put, call, race, select }) {
        while (true) {
          yield take("auth/logout/@@end");
          const isInitialized = yield select(states => states.dashboard.isInitialized);
          if (isInitialized) {
            yield put({ type: "resetState" });
          }
        }
      }, { type: 'watcher' },
    ],
    watchUpdateAccountInfo: [
      function* watch({ take, put, call, race, select }) {
        while (true) {
          yield take("auth/updateAccountInfo/@@end");
          const {
            staffInfo,
            isInitialized,
          } = yield select(state => ({
            staffInfo: state.auth.staffInfo,
            isInitialized: state.dashboard.isInitialized,
          })) || {};
          // console.log("🚀 ~ staffInfo:", JSON.stringify(staffInfo, null, 2));

          // 角色----
          const role = staffInfo.role;
          // 管理员
          const isAdmin = role === "admin";
          // 店长
          const isManager = role === "manager";
          // 商品经理
          const isProductManager = role === "ProductManager";
          // 销售(蓝端)
          const isSale = role === "sale";

          // 权限开关----
          // 允许查看供货商看板
          const allowViewSupplierDashboard = +staffInfo.allow_view_supplier_dashboard === 1;
          // 允许查看供货商欠款/余额
          const showSupplierDebt = +staffInfo.show_supplier_debt === 1;
          // 允许查看客户欠款/余额
          const showClientDebt = +staffInfo.show_client_debt === 1;
          // 允许查看供货商列表
          const viewSupplier = +staffInfo.view_supplier === 1;
          // 允许查看客户详细资料
          const viewClientInfo = +staffInfo.view_client_info === 1;

          const permission = {
            role,
            isAdmin,
            isManager,
            isProductManager,
            isSale,
            // 能否看上游:
            // 管理员   true
            // 店长     allowViewSupplierDashboard
            // 商品经理  allowViewSupplierDashboard
            // 销售     false
            allowViewUpstreams: isAdmin || (
              (isManager && allowViewSupplierDashboard) ||
              (isProductManager && allowViewSupplierDashboard)
            ),
            // 能否看下游:
            // 管理员   true
            // 店长     allowViewSupplierDashboard
            // 商品经理  allowViewSupplierDashboard
            // 销售     allowViewSupplierDashboard
            allowViewDownstreams: isAdmin || (
              (isManager && allowViewSupplierDashboard) ||
              (isProductManager && allowViewSupplierDashboard) ||
              (isSale && allowViewSupplierDashboard)
            ),
            // 能否看上游欠款:
            // 管理员   true
            // 店长     viewSupplier
            // 商品经理  false
            // 销售     showSupplierDebt
            showSupplierDebt: isAdmin || (
              (isManager && viewSupplier) ||
              (isSale && showSupplierDebt)
            ),
            // 能否看下游欠款:
            // 管理员   true
            // 店长     viewClientInfo
            // 商品经理  false
            // 销售     showClientDebt
            showClientDebt: isAdmin || (
              (isManager && viewClientInfo) ||
              (isSale && showClientDebt)
            ),
          };
          yield put({ type: "setState", payload: { permission } });
        }
      }, { type: 'watcher' },
    ],
  },
};
