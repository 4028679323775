import React from 'react';
import { TextInput } from 'react-native';

export default ( props ) => {
  const mixedProps = {...props};
  if ( mixedProps.underlineColorAndroid === undefined ) {mixedProps.underlineColorAndroid = 'transparent';}
  if ( mixedProps.style === undefined ) {
    mixedProps.style = { padding: 0 };
  } else if (!Array.isArray(mixedProps.style)) {
    mixedProps.style = [{ padding: 0 }, mixedProps.style];
  } else {
    mixedProps.style.unshift({ padding: 0 });
  }
  return (
    <TextInput {...mixedProps} />
  );
};
