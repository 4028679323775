
export default {
  namespace: 'localStore',
  state: {

  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
