import qs from "qs";

export const getUrlWithoutSearch = () => window.location.href.split("?")[0];

export const getSearchParams = () => qs.parse(window.location.search.slice(1));

export const getSearchKey = key => {
  const searchParams = getSearchParams();
  return searchParams?.[key];
};

// replaceState只改变当前url,不会刷新页面,后退页面会变回来。
export const replaceSearch = (searchParams = {}) => {
  const baseUrl = getUrlWithoutSearch();
  const newSearch = Object.keys(searchParams).length ? `?${qs.stringify(searchParams)}` : "";
  window.history.replaceState({}, "", `${baseUrl}${newSearch}`);
};

export const replaceSearchKey = (key, value) => {
  const searchParams = getSearchParams();
  replaceSearch(setKey(searchParams, key, value));
};

export const replaceSearchKeys = keys => {
  const searchParams = getSearchParams();
  replaceSearch(
    keys.reduce(
      (t, c) => setKey(t, c[0], c[1]),
      searchParams
    )
  );
};

export const removeSearchKeys = keys => replaceSearchKeys(keys.map(i => [i]));

const setKey = (obj, key, value) => {
  if (value === undefined) {
    delete obj[key];
  } else {
    obj[key] = value;
  }
  return obj;
};
