import React from 'react';
import { connect } from 'react-redux';
import NavigationButton from './NavigationButton';
import { navigateBack } from '@utils/navigation';

class BackButton extends React.PureComponent {

  static defaultProps = {
    type: '',
    icon: 'back',
  }

  render() {
    return (
      <NavigationButton
        icon={this.props.icon}
        type={this.props.type}
        onPress={navigateBack}
      />
    );
  }
}

export default connect(null)(BackButton);
