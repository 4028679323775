
export function reduceExtender(sourceOrigin, extend = {}) {
  const origin = {...sourceOrigin};
  Array.from(new Set([
    ...Object.keys(origin),
    ...Object.keys(extend)
  ])).forEach((key)=>{
    if ( typeof origin[key] === 'object' || typeof extend[key] === 'object' ) {
      origin[key] = {
        ...(origin[key] || {}),
        ...(extend[key] || {})
      };
    }
  });
  return origin;
}

export const factory = ({
  namespace,
  extender = origin=>origin
}) => {
  const origin = {
    namespace,
    state: {},
    reducers: {},
    effects: {},
    subscriptions: {}
  };
  const extend = extender(origin);
  return reduceExtender(origin, extend);
};
