import React from 'react';
import { Image, Text, StyleSheet } from 'react-native';
import { Consumer } from './context';
import resources from '@config/resources';
import TouchableWithPosition from '@components/TouchableWithPosition';
import { connectPreset } from '@utils/other';

const availableIcons = [
  "add",
  "addBold",
  "addCircle",
  "back",
  "close",
  "more",
  "moreHollow",
];
const iconSizeMap = {
  "navAddCircleWhiteMC": { width: 44, height: 44 },
  "navMoreHollowWhiteMC": { width: 44, height: 44 },
};

class NavigationButton extends React.PureComponent {

  static defaultProps = {
    text: undefined,
    type: undefined,
    icon: undefined,
    darkIcon: undefined,
    lightIcon: undefined,
    disabled: false,
    style: undefined,
    textStyle: undefined,
  }

  renderIcon = (primary) => {
    const { theme, icon, darkIcon, lightIcon, iconStyle } = this.props;
    let source, iconName;
    if (typeof icon !== "string") {
      source = (primary ? lightIcon : darkIcon) || icon || darkIcon || lightIcon;
    } else {
      const name = `${icon[0].toUpperCase()}${icon.slice(1)}`;
      const priorityColor = primary ? "White" : "Black"; // 优先颜色
      const alternativeColor = !primary ? "White" : "Black"; // 备选颜色
      // 命名: "nav" + name + color? + theme?
      iconName = [
        icon,
        `nav${name}${priorityColor}${theme}`,
        `nav${name}${alternativeColor}${theme}`,
        `nav${name}${priorityColor}`,
        `nav${name}${alternativeColor}`,
        `nav${name}`,
      ].find(i => resources[i] !== undefined);
      if (!iconName) {
        // 兜底找其他theme的图标
        iconName = (
          Object.keys(resources).find(i => i.indexOf(`nav${name}${priorityColor}`) > -1) ||
          Object.keys(resources).find(i => i.indexOf(`nav${name}${alternativeColor}`) > -1)
        );
      }
      source = resources[iconName];
    }

    return (
      source === undefined ? null : (
        <Image
          style={[
            { width: 40, height: 40 },
            iconSizeMap[iconName],
            iconStyle,
          ]}
          source={source}
        />
      )
    );
  }

  render() {

    return (
      <Consumer>
        {ctx => {
          let { theme, type, text, icon, darkIcon, lightIcon, disabled, style, textStyle, ...rest } = this.props;
          const finalType = (type || ctx.type);
          const contentType = text === undefined ? 'icon' : 'text';
          // 按钮禁用时，自动剔除 onPress 方法
          if (disabled) {
            delete rest.onPress;
          }
          return (
            <TouchableWithPosition
              style={[
                styles[`style_contentType_${contentType}`],
                styles[`style_contentType_${contentType}_${theme}`],
                style,
              ]}
              disabled={disabled}
              {...rest}
            >
              {
                contentType === 'icon' ? (
                  this.renderIcon(finalType === "primary")
                ) : (
                  <Text
                    style={[
                      styles.textStyle,
                      styles[`textStyle_type_${finalType}`],
                      styles[`textStyle_type_${finalType}_${theme}`],
                      styles[`textStyle_disabled_${disabled}`],
                      styles[`textStyle_disabled_${disabled}_${theme}`],
                      textStyle,
                    ]}
                  >{text}</Text>
                )
              }
            </TouchableWithPosition>
          );
        }}
      </Consumer>
    );
  }
}


const styles = StyleSheet.create({
  style_contentType_icon: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  style_contentType_icon_MC: {
    width: 44,
    height: 44,
  },
  style_contentType_icon_MCS: {
    width: 44,
    height: 44,
  },
  style_contentType_text: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  style_contentType_text_MC: {
    height: 44,
    paddingHorizontal: 11,
  },
  style_contentType_text_MCS: {
    height: 44,
    paddingHorizontal: 11,
  },

  textStyle: {
    fontSize: 15,
    letterSpacing: 0.44,
  },
  // normal
  textStyle_type_normal: {
    color: "#000",
  },
  textStyle_type_normal_MC: {
    fontWeight: "bold",
  },
  textStyle_type_normal_MCS: {
    fontWeight: "bold",
  },
  textStyle_type_normal_MS: {
    color: "#7265BA",
  },
  textStyle_type_normal_NANO: {
    color: "#008AFF",
    fontWeight: "bold",
  },
  // primary
  textStyle_type_primary: {
    color: "#fff",
  },
  textStyle_type_primary_MC: {
    fontWeight: "bold",
  },
  textStyle_type_primary_MCS: {
    fontWeight: "bold",
  },
  textStyle_type_primary_MS: {
  },
  textStyle_type_primary_NANO: {
    color: "#008AFF",
    fontWeight: "bold",
  },
  // disbaled
  textStyle_disabled_true: {
    color: '#A7A19A',
    fontWeight: "normal",
  },
  textStyle_disabled_true_MS: {
    color: '#A0A0A0',
  },
});

export default connectPreset(["theme"])(NavigationButton);
