import moment from 'moment';

export const formatTimeToMD = timestamp => {
  const t = moment(timestamp);
  const L = t.format("L");
  const YYYY = t.format("YYYY");

  const res = L.replace(new RegExp(`[-\\/.]‏*\\s*${YYYY}`, "g"), '').replace(new RegExp(`${YYYY}[-\\/.]‏*\\s*`, "g"), '');

  return res;
};
