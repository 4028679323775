import * as salesChannelService from '../services/salesChannel';
import { handleRequestError } from './../utils';

export default {
  namespace: "salesChannel",
  state: {
  },
  reducers: {

  },
  effects: {
    *getSalesChannels({ payload }, { call, put, select }) {
      const res = yield call(salesChannelService.getSalesChannels, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *patchSingle({ payload }, { call, put, select }) {
      const res = yield call(salesChannelService.patchSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *deleteSingle({ payload }, { call, put, select }) {
      const res = yield call(salesChannelService.deleteSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
  },
};
