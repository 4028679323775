import envConf from './env';
import appConfSource from './app';
import appConfBuilded from './_appConfig.build.json';
import resources from './resources';

const appConf = {
  ...appConfSource,
  ...appConfBuilded,
};

export {
  envConf,
  appConf,
  resources,
};
