import React from 'react';
import { View, Text } from 'react-native';
import { Theme } from "ui-m/rn";



/*
  两种布局方式
  1.横向布局
    a.居左
    b.居右
  2.纵向布局
    a.居左
    b.居右

  layoutConfig: {
    direction: "row" | "column"
    align: "left" | "right"
    gap: 间隔 | [水平间隔, 垂直间隔]
  }

 */
const getDefaultLayoutConfig = params => {
  const { isPhoneSizedWindow } = params;

  return (
    isPhoneSizedWindow ? {
      direction: "column",
      align: "right",
      gap: 10,
    } : {
      direction: "row",
      align: "left",
      gap: 10,
    }
  );
};

const FormItemGroup = props => {
  const {
    style,
    getLayoutConfig = getDefaultLayoutConfig,
    children,
  } = props;

  const layoutConfig = getLayoutConfig({
    isPhoneSizedWindow: Theme.isPhoneSizedWindow,
  });

  const { direction, align, gap } = layoutConfig;
  const horizontalGap = Array.isArray(gap) ? gap[0] : gap;
  const vertivalGap = Array.isArray(gap) ? gap[1] : gap;

  const wrapStyle = {};
  const itemStyle = {};
  wrapStyle.marginBottom = -vertivalGap;
  itemStyle.marginBottom = vertivalGap;
  if (direction === "column") {
    wrapStyle.flexDirection = "column";

    if (align === "left") {
      wrapStyle.alignItems = "flex-start";
    } else if (align === "right") {
      wrapStyle.alignItems = "flex-end";
    }

  } else if (direction === "row") {
    wrapStyle.flexDirection = "row";
    wrapStyle.flexWrap = "wrap";

    if (align === "left") {
      wrapStyle.justifyContent = "flex-start";
      itemStyle.marginRight = horizontalGap;
    } else if (align === "right") {
      wrapStyle.justifyContent = "flex-end";
      itemStyle.marginLeft = horizontalGap;
    }
  }

  const _children = [];
  React.Children.map(children, (i, ii) => {
    if (!React.isValidElement(i)) {
      return;
    }

    const key = i.key || `${_children.length}`;

    _children.push(
      <View key={key} style={itemStyle} >{i}</View>
    );
  });

  return (
    <View style={[wrapStyle, style]}>{_children}</View>
  );
};

const FormItem = props => {
  const { label, labelStyle, children, style } = props;

  return (
    <View style={[{ flexDirection: "row", alignItems: "flex-start" }, style]}>
      {
        !!label && (
          <Text
            style={[{
              fontSize: 14,
              lineHeight: 20,
              paddingVertical: 6,
              color: "#000",
              opacity: 0.9,
              marginRight: 10,
              textAlign: "right",
              wordBreak: "break-all",
            }, labelStyle]}
          >{label}:</Text>
        )
      }
      <View>{children}</View>
    </View>
  );
};

FormItem.Group = FormItemGroup;

export default FormItem;
