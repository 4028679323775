import en_GB from "antd/es/locale/en_GB.js";
import en_US from "antd/es/locale/en_US.js";
import fr_BE from "antd/es/locale/fr_BE.js";
import fr_CA from "antd/es/locale/fr_CA.js";
import fr_FR from "antd/es/locale/fr_FR.js";
import es_ES from "antd/es/locale/es_ES.js";
import pt_BR from "antd/es/locale/pt_BR.js";
import pt_PT from "antd/es/locale/pt_PT.js";
import tr_TR from "antd/es/locale/tr_TR.js";
import zh_CN from "antd/es/locale/zh_CN.js";
import zh_HK from "antd/es/locale/zh_HK.js";
import zh_TW from "antd/es/locale/zh_TW.js";
module.exports = { 'en_GB': en_GB, 'en_US': en_US, 'fr_BE': fr_BE, 'fr_CA': fr_CA, 'fr_FR': fr_FR, 'es_ES': es_ES, 'pt_BR': pt_BR, 'pt_PT': pt_PT, 'tr_TR': tr_TR, 'zh_CN': zh_CN, 'zh_HK': zh_HK, 'zh_TW': zh_TW,  };
