// 用于跨作用域进行页面导航
import React from 'react';
import async from 'crow/utils/async';
import { CommonActions, StackActions } from '@react-navigation/native';
import { preloadScreenOrStack } from '../router';

let _isReady, _navigationReadyResolve;

const _navigationReadyPromise = new Promise(resolve => {
  _navigationReadyResolve = resolve;
});

export async function _setNavigationReady() {
  // 绕过 react-navigation 的 bug（onReady的时机不靠谱）
  await async.waitUntil(() => {
    return !!navigationRef.current?.getCurrentRoute();
  });
  _isReady = true;
  _navigationReadyResolve();
}

export const navigationRef = React.createRef();

export async function getNavigation() {
  if (_isReady) {
    return navigationRef.current;
  }
  return _navigationReadyPromise.then(() => {
    return navigationRef.current;
  });
}


let lastRootState, lastCurrentRoute;

export function _setLastRootState(val) {
  if (val) {
    lastRootState = val;
  }
}

export function _setLastCurrentRoute(val) {
  if (val) {
    lastCurrentRoute = val;
  }
}

export function getLastRootState() {
  return lastRootState;
}

export function getLastCurrentRoute() {
  return lastCurrentRoute;
}

export async function navigate(...params) {
  const navigation = await getNavigation();
  await preloadScreenOrStack(params[0]);
  return navigation.navigate(...params);
}

export async function navigateReplace(...params) {
  const navigation = await getNavigation();
  await preloadScreenOrStack(params[0]);
  return (
    navigation.dispatch(
      StackActions.replace(...params)
    )
  );
}

export async function navigateBack() {
  const navigation = await getNavigation();
  return navigation.goBack();
}

export async function navigateReset(param) {
  const navigation = await getNavigation();
  await Promise.all(param.routes.map(route => preloadScreenOrStack(route.name)));
  return (
    navigation.dispatch(
      CommonActions.reset(param)
    )
  );
}

export const createNavigateShortcut = nameOrRoutes => {
  if (Array.isArray(nameOrRoutes)) {
    return encodeURIComponent(JSON.stringify(nameOrRoutes));
  } else if (typeof nameOrRoutes === "string") {
    return nameOrRoutes; // 字符串就不需要 JSON.stringify
  } else {
    return "";
  }
};

export const parseNavigateShortcut = shortcut => {
  if (typeof shortcut !== "string") {
    return;
  }
  let routes;
  try {
    // routes
    routes = JSON.parse(shortcut);
    if (Array.isArray(routes)) {
      return routes;
    }
  } catch (error) {
    // navigateName
    return [{ name: shortcut }];
  }
};
