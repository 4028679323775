import React, { useRef } from "react";
import Touchable from "./Touchable";

const TouchableWithPosition = props => {
  const { onPress, ...rest } = props;
  const ref = useRef(null);

  return (
    <Touchable
      ref={ref}
      onPress={() =>
        ref.current.measureInWindow(
          (x, y, width, height) =>
            onPress?.({ x, y, width, height })
        )
      }
      {...rest}
    />
  );
};

export default TouchableWithPosition;
