import React from "react";
import { useSelector } from "react-redux";
import { LoadingMore } from "ui-m/rn";

const ListFooter = props => {
  const { hasMore, isLoading } = props;
  const i18n = useSelector(state => state.i18n);

  return (isLoading || hasMore) ? (
    <LoadingMore loading title={i18n.map["mch.toast.loading"]}/>
  ) : (
    <LoadingMore loading={false} title={i18n.map["cmn.no_more_content"]} />
  );
};

ListFooter.loading = () => (
  <ListFooter isLoading={true} />
);

ListFooter.noMore = () => (
  <ListFooter isLoading={false} hasMore={false} />
);

ListFooter.preset = props => {
  const { type }  = props;

  if (type === "loading") {
    return (<ListFooter.loading/>);
  }
  if (type === "noMore") {
    return (<ListFooter.noMore/>);
  }
  return null;
};

export default ListFooter;
