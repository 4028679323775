import _ from 'lodash';
import * as countryServices from '../../services/country';
import RNLocalize from 'react-native-localize';
import { createAction } from '../../utils';

export default {
  namespace: 'country',
  state: {
    list: [],
    selectCountry: {},
    loginPhoneCountry: {},
    currentLang: '',
    deviceLocaleCountry: {},

    isListLoading: false,
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    advanceSingle(state, { payload }) {
      let advanceList = [...state.list];
      if (payload.regionCode) {
        advanceList = [
          _.find(advanceList, c => c.regionCode === payload.regionCode),
          ..._.remove(advanceList, c => c.regionCode !== payload.regionCode),
        ];
      }
      return { ...state, list: advanceList };
    },
    sort(state, { payload }) {

      let countryList = state.list;

      if (payload.type === 'name') {
        countryList = _.sortBy(countryList, i => i.name);
      }
      if (payload.type === 'countryCode') {
        countryList = _.sortBy(countryList, i => Number(i.countryCode));
      }

      // 默认提前国家
      countryList = [
        _.find(countryList, i => i.regionCode === 'FR'), // 法国
        _.find(countryList, i => i.regionCode === 'IT'), // 意大利
        _.find(countryList, i => i.regionCode === 'BR'), // 巴西
        _.find(countryList, i => i.regionCode === 'GB'), // 英国
        _.find(countryList, i => i.regionCode === 'ES'), // 西班牙
        _.find(countryList, i => i.regionCode === 'PT'), // 葡萄牙
        _.find(countryList, i => i.regionCode === 'BE'), // 利比亚
        _.find(countryList, i => i.regionCode === 'NL'), // 荷兰
        _.find(countryList, i => i.regionCode === 'DE'), // 德国
        ..._.remove(countryList, i => !['FR', 'IT', 'BR', 'GB', 'ES', 'PT', 'BE', 'NL', 'DE'].includes(i.regionCode)),
      ];

      // 提前当前选择国家
      if (payload.firstCountry && _.find(countryList, i => i.regionCode === payload.firstCountry)) {
        countryList = [
          _.find(countryList, i => i.regionCode === payload.firstCountry),
          ..._.remove(countryList, i => i.regionCode !== payload.firstCountry),
        ];
      }
      return { ...state, list: countryList };
    },
  },
  effects: {
    *getList({ payload }, { call, put, select }) {
      const res = yield call(countryServices.getList, payload);

      if (res.success) {
        let countryList = res.data;

        countryList = [
          _.find(countryList, i => i.regionCode === 'FR'), // 法国
          _.find(countryList, i => i.regionCode === 'IT'), // 意大利
          _.find(countryList, i => i.regionCode === 'BR'), // 巴西
          _.find(countryList, i => i.regionCode === 'GB'), // 英国
          _.find(countryList, i => i.regionCode === 'ES'), // 西班牙
          _.find(countryList, i => i.regionCode === 'PT'), // 葡萄牙
          _.find(countryList, i => i.regionCode === 'BE'), // 利比亚
          _.find(countryList, i => i.regionCode === 'NL'), // 荷兰
          _.find(countryList, i => i.regionCode === 'DE'), // 德国
          ..._.remove(countryList, (c) => !['FR', 'IT', 'BR', 'GB', 'ES', 'PT', 'BE', 'YE', 'DE'].includes(c.regionCode)),
        ];

        const deviceCountryCode = RNLocalize.getCountry();
        const deviceLocaleCountry = countryList.find(c => c.countryCode === deviceCountryCode) || countryList[0];

        yield put(createAction('setState')({
          list: countryList,
          deviceLocaleCountry,
        }));
      }

      return res;
    },
  },
};
