const fnMap = {};

const loadFunction = async fnName => {

  if (fnMap[fnName]) {
    return fnMap[fnName];
  }

  if (fnName === "activateDashboard") {
    const { activateDashboard } = await import("./activateDashboard");
    fnMap.activateDashboard = activateDashboard;
    return activateDashboard;
  }

};

export const lazyFunction = (fnName, ...params) => loadFunction(fnName).then(fn => fn?.(...params));
