import resources from './resources';

export default [
  {
    key: 'toastSucceed',
    source: require('../assets/toast/succeed.png'),
    width: 44,
    height: 44,
  },
  {
    key: 'toastFailed',
    source: require('../assets/toast/failure.png'),
    width: 44,
    height: 44,
  },
];
