import React, { useEffect, useRef, useState, useCallback } from "react";
import localize from "crow/utils/localize";
import { Input } from "ui-m/rn";
import Decimal from "decimal.js";

const useStateWithRef = initialValue => {
  const ref = useRef(initialValue);
  const [value, setValue] = useState(initialValue);

  const _setValue = useCallback(newVal => {
    ref.current = newVal;
    setValue(newVal);
  }, []);

  const getRef = useCallback(() => ref.current, []); // 为了获取最新值

  return [value, _setValue, getRef];
};

const NumberInput = props => {
  const {
    value,
    valid,
    onComplete,
    ...rest
  } = props;

  const displayValue = (
    ["", null, undefined].includes(value) ? "" : // 无值
      isNaN(+value) ? "" : // 无效值
        localize.toLNU(+value) // 有效值
  );

  const [inputText, setInputText, getInputText] = useStateWithRef("");
  const [isFocus, setIsFocus, getIsFocus] = useStateWithRef(false);

  const inputCompleted = () => {
    const text = getInputText();
    const num = +localize.fromLNU(text);
    // 没填
    if (text === "") {
      return onComplete();
    }
    // 值无效
    if (typeof valid === "function" && !valid(num)) {
      return onComplete(value);
    }

    onComplete(num);
  };

  const onChangeText = (newVal) => {
    setInputText(localize.toLNU(localize.fromLNU(newVal)));
  };

  const onFocus = () => {
    setInputText(localize.toLNU(displayValue));
    setIsFocus(true);
  };

  const onBlur = () => {
    inputCompleted(inputText);
    setInputText("");
    setIsFocus(false);
  };

  useEffect(() => {

    return () => {
      // 处于focus中时，直接从节点树移除，不会触发blur，可能会丢失输入
      // 忽略可能的inputCompleted变化
      getIsFocus() && inputCompleted();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      keyboardType="decimal-pad"
      {...rest}
      value={isFocus ? inputText : displayValue}
      onChangeText={onChangeText}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

const PercentInput = props => {
  const {
    value,
    valid,
    onComplete,
    ...rest
  } = props;

  const numToPercent = num => {
    if (
      ["", null, undefined].includes(num) ||
      isNaN(+num)
    ) {
      return num;
    }

    return +Decimal.mul(+num, 100); // 乘100
  };

  const percentToNum = percent => {
    if (
      ["", null, undefined].includes(percent) ||
      isNaN(+percent)
    ) {
      return percent;
    }

    return +Decimal.div(+percent, 100); // 除100
  };

  return (
    <NumberInput
      {...rest}
      value={numToPercent(value)}
      valid={percent => {
        if (typeof valid !== "function") {
          return true;
        }
        return valid(percentToNum(percent));
      }}
      onComplete={percent => onComplete?.(percentToNum(percent))}
    />
  );
};

NumberInput.Percent = PercentInput;

export default NumberInput;
