import React from 'react';
import { Platform } from 'react-native';
import { ConfigProvider } from 'antd';
import { Theme } from 'ui-m/rn';
import { connect } from 'react-redux';
import antdLocales from './config/antdLocales.build.js';

class AntDConfigProvider extends React.PureComponent {
  get locale(){
    let localeName;
    let { currentLang, currentRegion } = this.props;
    if (!antdLocales) {
      return null;
    }
    else {
      if (currentLang === 'zh_hans') {
        currentLang = 'zh';
      }
      localeName = `${currentLang}_${currentRegion.toUpperCase()}`;
      const availableOptions = Object.keys(antdLocales);
      if (!availableOptions.includes(localeName)) {
        localeName = availableOptions.find(name => {
          let guessRegion = currentLang.toUpperCase();
          if (currentLang === 'en') {
            guessRegion = 'GB';
          }
          return name === `${currentLang}_${guessRegion}`;
        });
        if (!localeName) {
          localeName = availableOptions.find(name => {
            return name.indexOf(`${currentLang}_`) === 0;
          });
        }
        if (!localeName) {
          localeName = 'en_GB';
        }
      }
    }
    return antdLocales[localeName];
  }
  render() {
    const { children, ...rest } = this.props;
    const locale = this.locale;
    if (Platform.OS === 'web' && !Theme.isPhoneSizedWindow && locale) {
      return <ConfigProvider locale={locale} {...rest}>{children}</ConfigProvider>;
    }
    else {
      return children;
    }
  }
}

export default connect(({i18n}) => ({
  currentLang: i18n.currentLang,
  currentRegion: i18n.currentRegion,
}))(AntDConfigProvider);
