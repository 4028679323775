import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Dialog, LabelBlock, NavigationPage, NavigationBar } from '@components';
import { openMC, callNativeClose, navigate } from '@utils';
import { CellGroup, Cell, Space } from "ui-m/rn";
import NumberInput from '@components/NumberInput';


class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return "Create E-shipping label";
    },
    showBackButton: true,
  };

  constructor(props) {
    super(props);

    const orderId = props.route.params?.orderId;
    const shippingRecordId = props.route.params?.shippingRecordId;
    console.log("Create E-shipping label", JSON.stringify({ orderId, shippingRecordId }, null, 2));

    this.state = {
      weight: 1,
      insurance: null,
    };
  }

  renderPage() {
    const { i18n } = this.props;

    console.log("🚀 ~ this.state:", JSON.stringify(this.state, null, 2));

    return (
      <ScrollView style={styles.container}>
        {/* <View style={{ paddingHorizontal: 15 }}>
          <NumberInput
            label="weight"
            value={this.state.weight}
            valid={val => val > 0}
            onComplete={val => this.setState({ weight: val })}
          />
        </View> */}
        <View style={{ paddingHorizontal: 15 }}>
          <NumberInput
            label="insurance"
            left="€"
            value={this.state.insurance}
            valid={val => val >= 0}
            onComplete={val => this.setState({ insurance: val })}
          />
        </View>
        <Space scene="pageFooter" />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

});

export default connect((store) => _.pick(store, [
  'i18n',
  "app",
]))(Page);
