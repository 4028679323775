//
//  type:
//    1.num: 纯数字
//    2.float: 两位小数
export const inputValueLimit = (value, type) => {
  // 兼容外文,
  let returnValue = value.replace(',', '.');
  if (type === 'num') {
    returnValue = returnValue.replace(/[^0-9]/ig,'');
  }
  if (type === 'float') {
    if (value === '.') {
      return '';
    }
    if (value === '') {
      return '';
    }
    let returnValueAry = returnValue.replace(/[^0-9.]/ig,'').split('.');
    returnValue = `${Number(returnValueAry.shift())}`;
    if (returnValueAry.length) {
      returnValue = returnValue + '.';
      returnValueAry.forEach(i => {
        if (i) {
          returnValue = returnValue + i;
        }
      });
    }
    // 保留两位小数
    if (returnValue.split('.')[1] && returnValue.split('.')[1].length > 2) {
      returnValue = Number(returnValue).toFixed(2);
    }
  }
  return returnValue;
};
