import React from "react";
import { StyleSheet, View, Image, Text } from "react-native";
import { resources } from "../config";


const Info = props => {
  const { containerStyle, iconStyle, textStyle } = props;
  const icon = props.icon || resources.infoCircle;
  const text = props.text;

  return (
    <View style={[styles.wrap, containerStyle]}>
      <Image style={[styles.icon, iconStyle]} source={{ uri: icon }} />
      {!!text && <Text style={[styles.text, textStyle]}>{text}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    paddingVertical: 12,
    paddingHorizontal: 15,
    backgroundColor: "#F4F4F4",
    borderRadius: 4,
    flexDirection: "row",
  },
  icon: {
    width: 16,
    height: 16,
    marginVertical: 1,
  },
  text: {
    fontSize: 14,
    lineHeight: 18,
    flex: 1,
    color: "#666",
    marginLeft: 8,
  },
});

export default Info;
