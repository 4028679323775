import { Platform, Text } from 'react-native';
import { Icon, Theme, Switch } from 'ui-m/rn';
import icons from './config/icons';

/* This scripe make the body full-height normally out of APP WebView */
if (Platform.OS === 'web') {
  Theme.set({
    isWebInAPP: /(\?|&)(mcKey|key|loginToken)=/.test(window.location.search),
  });
}

// 非手机屏幕（Pad、PC）的网页版，默允许复制文字内容
if (!Theme.isPhoneSizedWindow && Platform.OS === 'web') {
  if (Text.defaultProps == null) {
    Text.defaultProps = {};
  }
  Text.defaultProps.selectable = true;
}

Icon.registerBuiltInIcons(icons);

if (Platform.OS === "web") {
  const style = document.createElement("style");
  style.innerHTML = `.pswp__top-bar { top: ${Theme.statusBarHeight}px }`;
  document.head.appendChild(style);
}

export const loadTheme = async (type) => {
  if (type === "MS") {
    await import("ui-m/rn/themes/microStoreManager");
    return;
  }
  if (type === "NANO") {
    await import("ui-m/rn/themes/mshop");
    return;
  }

  await import("ui-m/rn/themes/mc");
  Switch.defaultProps.activeColor = '#39cd81';
  Switch.defaultProps.activeThumbColor = "#fff";
};
