import React from 'react';
import ReactDOM from 'react-dom';
import { AppRegistry, Platform, LogBox, Alert } from 'react-native';
import 'moment/min/locales.min.js';
import './theme';

import Router from './router';
import app from './dva';

import { appConf } from './config';
import { connect, Provider } from 'react-redux';
import bgMessaging from './utils/appNotifications/bgMessaging';
import { Teaset } from 'ui-m/rn';
const { TopView, fitRedux } = Teaset;
import kvlogService from './services/kvlog';
import { setJSExceptionHandler, setNativeExceptionHandler } from 'react-native-exception-handler';
import RNRestart from 'react-native-restart';
import { select } from './utils/store';
import AntdConfigProvider from './AntdConfigProvider';

import { initGlobalUtils } from '@globalUtils';





setJSExceptionHandler(async (e, isFatal) => {
  console.warn('JS ERROR', e);
  kvlogService.postException(e, {
    catchedBy: 'setJSExceptionHandler',
    errorType: isFatal ? 'JS_ERROR_FATAL' : 'JS_ERROR_NORMAL',
    errorIsFatal: isFatal,
  });
  if (isFatal) {
    const i18n = await select(({ i18n }) => i18n);
    Alert.alert(
      i18n.map.an_unexpected_err_28,
      i18n.map.an_unexpected_err_desc_31,
      [{
        text: i18n.map.restart,
        onPress: () => {
          RNRestart.Restart();
        },
      }]
    );
  } else {
    console.log(e); // So that we can see it in the ADB logs in case of Android if needed
  }
});

setNativeExceptionHandler((errorString) => {
  console.warn('Native Error', errorString);
  kvlogService.postException(errorString, {
    catchedBy: 'setNativeExceptionHandler',
    errorType: 'NATIVE_ERROR',
    errorIsFatal: true,
  });
});

class App extends React.Component {
  render() {
    return (
      <Provider store={app._store}>
        <AntdConfigProvider>
          <TopView>
            <Router/>
          </TopView>
        </AntdConfigProvider>
      </Provider>
    );
  }
}

initGlobalUtils(app._store);

fitRedux();


AppRegistry.registerComponent(appConf.projectName, () => App);
if (Platform.OS === 'web') {
  ReactDOM.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
    ,
    document.getElementById('root'),
  );
}





// Ignore all log notifications
LogBox.ignoreAllLogs();

LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state', // for React-navigation
]);

