const AsyncStorage = require('@react-native-community/async-storage');
const appConf = require('./app');

module.exports = {
  configMap: {

    get prod(){
      return {
      };
    },

    get test(){
      return {
      };
    },
  },

  get(){
    if (this._currentEnv === null) {
      return this.envReady().then(()=>({
        currentEnv: this._currentEnv,
        ...(this.configMap[this._currentEnv] || this.configMap.prod),
      }));
    }
    else {
      return {
        currentEnv: this._currentEnv,
        ...(this.configMap[this._currentEnv] || this.configMap.prod),
      };
    }
  },

  async getCurrentEnv(){
    return this.envReady().then(()=> this._currentEnv );
  },

  setEnv(env){
    if (Object.keys(this.configMap).includes(env)) {
      this._currentEnv = env;
      this._storeToLocal();
    }
  },

  async envReady(){
    if (!this._envReadyPromise) {
      // 运行环境
      if (typeof AsyncStorage.getItem === 'function') {
        this._envReadyPromise = AsyncStorage.getItem(this._currentEnvLocalStoreKey).then( env => this.setEnv(env) ).catch( e => {
          console.warn('app/config/env.js:envReady()', e);
          this.setEnv('prod');
        });
      }
      // 打包环境
      else {
        this.setEnv(appConf.get({_forBuild: true}).defaultEnv || 'prod');
      }
    }
    return this._envReadyPromise;
  },

  _currentEnv: null,
  _currentEnvLocalStoreKey: 'env',
  _envReadyPromise: null,
  _storeToLocal(){
    if (typeof AsyncStorage.setItem === 'function') {
      AsyncStorage.setItem(this._currentEnvLocalStoreKey, this._currentEnv);
    }
  },

};
