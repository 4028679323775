module.exports = {
  langOptions: [
    {
      name: 'English',
      lang: 'en',
    },
    {
      name: 'Français',
      lang: 'fr',
    },
    // {
    //   name: 'Italiano',
    //   lang: 'it',
    // },
    {
      name: 'Español',
      lang: 'es',
    },
    {
      name: 'Português',
      lang: 'pt',
    },
    {
      name: 'Turkçe',
      lang: 'tr',
    },
    // {
    //   name: 'Pусский',
    //   lang: 'ru',
    // },
    // {
    //   name: 'Deutsch',
    //   lang: 'de',
    // },
    // {
    //   name: 'Polski',
    //   lang: 'pl',
    // },
    // // 克罗地亚
    // {
    //   name: 'Hrvatski',
    //   lang: 'hr',
    //   type: 'translate',
    // },
    // // 匈牙利
    // {
    //   name: 'Magyarország',
    //   lang: 'hu',
    //   type: 'translate',
    // },
    // // 罗马尼亚
    // {
    //   name: 'România',
    //   lang: 'ro',
    //   type: 'translate',
    // },
    // // 斯洛文尼亚
    // {
    //   name: 'Slovenija',
    //   lang: 'sl',
    //   type: 'translate',
    // },
    // // 塞尔维亚语（拉丁）
    // {
    //   name: 'Srpski (latinica)',
    //   lang: 'sr-Latn',
    //   type: 'translate',
    // },
    // {
    //   name: 'عربي/عربى',
    //   lang: 'ar',
    //   isRTL: true,
    // },
    {
      name: '中文',
      lang: 'zh_hans',
    },
  ],
};
