import {request} from '../utils';
import {appConf} from '../config';
import Storage from '../utils/storage';
import i18nBuildCacheMap from '../config/_i18nCacheMap.build.json';
import i18nBuildCacheLastModifyTime from '../config/_i18nCacheMapLastModifyTime.build.json';
const {
  i18nWordServiceApiBase,
  i18nWordsFilterTags = [],
} = appConf.get();

let lastFetchWordsMapServerTime;

const I18N_CURRLANG_STORE_KEY = 'i18nCurrentLang';
const I18N_USEDEVICE_STORE_KEY = 'i18nUseDeviceLang';
const I18N_INCREMENTAL_CACHE_STORE_KEY = 'i18nIncrementalCache';
const I18N_INCREMENTAL_CACHE_LAST_TIME_STORE_KEY = 'i18nIncrementalCacheLastTime';
// 使用全量 build 生成的时间戳 map 作为标记，如果变化，说明全量数据有更新（APP包整个更新），此时可以忽略所有本地增量缓存，避免缓存无限增大。
const I18N_LAST_BUILD_TIMEMAP_STORE_KEY = 'i18nLastBuildTimeMap';


let i18nIncrementalCache = {
  // 'lang': { 'key': 'word word word...' }
};

let i18nIncrementalCacheLastModifyTime = {
  // 'lang': time,
};

async function saveCurrentLangToLocal({currentLang, useDeviceLang} = {}) {
  if ( currentLang !== undefined )  {
    await Storage.set(I18N_CURRLANG_STORE_KEY, currentLang);
  }
  if ( useDeviceLang !== undefined )  {
    await Storage.set(I18N_USEDEVICE_STORE_KEY, useDeviceLang);
  }
}

async function loadCurrentLangFromLocal() {
  const [
    currentLang,
    useDeviceLang,
  ] = await Promise.all([
    Storage.get(I18N_CURRLANG_STORE_KEY, null),
    Storage.get(I18N_USEDEVICE_STORE_KEY, null),
  ]);
  return {
    currentLang,
    useDeviceLang,
  };
}

async function getFinalWordMapOfLang(lang){
  await loadIncrementalCacheFromLocal();
  const incrementalMap = i18nIncrementalCache[lang] || {};
  const finalMap = {
    ...(i18nBuildCacheMap[lang] || {}),
  };
  // 增量数据覆盖全量数据
  // 跳过内容删空的词条
  Object.keys(incrementalMap).forEach(key=>{
    if (incrementalMap[key]) {
      finalMap[key] = incrementalMap[key];
    }
  });
  // 空词条默认用英语
  Object.keys(finalMap).forEach(key=>{
    if (!finalMap[key]) {
      finalMap[key] = (i18nIncrementalCache.en || {})[key] || (i18nBuildCacheMap.en || {})[key] || '';
    }
  });
  return finalMap;
}

function getFinalLastModifyTime(lang){
  return Math.max.apply(Math, [
    Number(i18nIncrementalCacheLastModifyTime[lang]) || 0,
    Number(i18nBuildCacheLastModifyTime[lang]) || 0,
  ]);
}

async function incrementalUpdateForLang(lang){
  await loadIncrementalCacheFromLocal();
  const res = await fetchWordsMap(lang, getFinalLastModifyTime(lang));
  if (res.success && res.data) {
    for (const key in res.data) {
      if (!i18nIncrementalCache[lang]) {
        i18nIncrementalCache[lang] = {};
      }
      i18nIncrementalCache[lang][key] = res.data[key];
    }
    i18nIncrementalCacheLastModifyTime[lang] = (lastFetchWordsMapServerTime || Date.now()) - 600000; // 留10分钟临界时间，避免漏词
    // 更新本地缓存
    saveIncrementalCacheToLocal();
  }
  return res;
}

async function saveIncrementalCacheToLocal(lang, cache) {
  return Promise.all([
    Storage.set(I18N_INCREMENTAL_CACHE_STORE_KEY, i18nIncrementalCache),
    Storage.set(I18N_INCREMENTAL_CACHE_LAST_TIME_STORE_KEY, i18nIncrementalCacheLastModifyTime),
    Storage.set(I18N_LAST_BUILD_TIMEMAP_STORE_KEY, i18nBuildCacheLastModifyTime),
  ]);
}

async function loadIncrementalCacheFromLocal(){
  const [
    _i18nIncrementalCache,
    _i18nIncrementalCacheLastModifyTime,
    _i18nBuildCacheLastModifyTime,
  ] = await Promise.all([
    Storage.get(I18N_INCREMENTAL_CACHE_STORE_KEY),
    Storage.get(I18N_INCREMENTAL_CACHE_LAST_TIME_STORE_KEY),
    Storage.get(I18N_LAST_BUILD_TIMEMAP_STORE_KEY),
  ]);
  // 使用全量 build 生成的时间戳 map 作为标记，如果变化，说明全量数据有更新（APP包整个更新），此时可以忽略所有本地增量缓存，避免缓存无限增大。
  if (_i18nBuildCacheLastModifyTime && JSON.stringify(_i18nBuildCacheLastModifyTime) !== JSON.stringify(i18nBuildCacheLastModifyTime)) {
    console.log('New build version of i18n cache was found, i18n cache has been restored.');
    i18nIncrementalCache = {};
    i18nIncrementalCacheLastModifyTime = {};
  }
  else {
    i18nIncrementalCache = _i18nIncrementalCache || {};
    i18nIncrementalCacheLastModifyTime = _i18nIncrementalCacheLastModifyTime || {};
  }
}


async function fetchSupportedLanguages() {
  return request(`${i18nWordServiceApiBase || ''}/languages`).then((res)=>{
    if ( res && res.data ) {
      res.data = res.data.map(src=>({
        name: src.name,
        lang: src.lag || src.lang,
      }));
    }
    return res;
  });
}

async function fetchWordsMap(lang, lastModifiedTime) {
  return request(`${i18nWordServiceApiBase || ''}/words/listAll`, { body: {
    lang,
    lastModifiedTime,
    tagList: i18nWordsFilterTags.join(','),
  } }).then((res)=>{
    const serverTime = res.response?.headers.get('date');
    // 只在货到时间的情况下，更新时间戳
    if (serverTime) {
      if ( res && res.data ) {
        lastFetchWordsMapServerTime = new Date(serverTime).getTime();
        const map = {};
        res.data.forEach(src => {
          map[src.key] = (src.values.filter(v=>v.lang === lang)[0] || src.values[0] || {}).value;
        });
        res.data = map;
      }
    }
    return res;
  });
}

async function getWords({keys, lang}) {
  return request(`${i18nWordServiceApiBase || ''}/words/queries?keys=${keys}&lang=${lang}`);
}


export default {
  getWords,
  fetchSupportedLanguages,
  // 以下本地缓存相关
  getFinalWordMapOfLang,
  saveCurrentLangToLocal,
  loadCurrentLangFromLocal,
  incrementalUpdateForLang,
};
