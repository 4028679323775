import React from 'react';
import { View } from 'react-native';
import { BasePage, ScreenLoading } from '@components';
import { getNavigation } from '../../utils/navigation';

export default class Page extends BasePage {

  async componentDidMount() {
    await getNavigation();
    if (!this.hide) {
      ScreenLoading.show();
    }
  }

  componentWillUnmount() {
    this.hide = true;
    ScreenLoading.hide();
  }

  renderPage = () => {
    return (
      <View />
    );
  }
}
