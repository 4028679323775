import React from 'react';
import { Image } from 'react-native';
import { factory } from './mixins/baseFactory';
import { TableOutlined } from '../../config/antdIcons';
import { resources } from '../../config';

export default factory({
  namespace: 'menu',
  extender: (origin) => ({
    state: {
      menuTree: [
        {
          labelI18nKey: 'stream.dashboard.Menu',
          icon: <TableOutlined />,
          // icon: <Image source={resources.menuDashboardReports} style={{ width: 16, height: 16 }} />, // 激活态
          children: [
            {
              labelI18nKey: 'mch.toast.loading',
              disabled: true,
            },
          ],
        },
      ],
      selectedKeys: [],
    },
    reducers: {
      setState(state, { payload }) {
        return { ...state, ...payload };
      },
      setDashboardMenu(state, { payload }) {
        const newMenuTree = state.menuTree;
        const index = newMenuTree.findIndex(i => i.labelI18nKey === "stream.dashboard.Menu");
        if (index > -1) {
          newMenuTree[index].children = payload;
        }

        return {
          ...state,
          menuTree: newMenuTree,
        };
      },
    },
    effects: {
      *onClick({ payload }, { call, put, select }) {

        // selectedKeys 自行控制
        yield put({ type: "dashboard/switchDashboard", payload: payload.data });

        return;
      },
    },
  }),
});


// export default [
//   key: `sub${key}`,
//   icon: React.createElement(icon),
//   label: `subnav ${key}`,
//   children: new Array(4).fill(null).map((_, j) => {
//     const subKey = index * 4 + j + 1;
//     return {
//       key: subKey,
//       label: `option${subKey}`,
//     };
//   }),
// ]
