import React from 'react';
import { StyleSheet } from 'react-native';
import { Teaset } from 'ui-m/rn';
const { Overlay, Theme } = Teaset;
import DialogContent from './DialogContent';

export default class Dialog {

  static show({
    type, boxStyle,
    title, titleStyle,
    content, contentStyle,
    icon, inputLimitType,
    placeholder, keyboardType, inputValue, inputMaxValue, inputStyle, hideInputBottomBorder, handelInput, beforeInputText, afterInputText, underInput, underInputWithCheck, underInputWithCheckText,
    leftBtnText, onLeftClick, leftBtnStyle,
    rightBtnText, onRightClick, rightBtnStyle, disabledRightBtnOnPromptEmpty, disabledRightBtnOnPromptZero, disabledRightBtnOnPromptPoint, showDelete,
    ...rest
  }) {
    return new Promise(resolve => {
      if (type === 'reload' || type === 'needReturn') {
        onLeftClick = () => {
          resolve('left');
        };
        onRightClick = () => {
          resolve('right');
        };
      }
      let dialogOverlay;
      let dialogOverlayKey;

      if (!dialogOverlay) {
        dialogOverlay = (
          <Overlay.View
            style={styles.overlay}
            modal
            overlayOpacity={null}
            ref={v => {this.dialogOverlay = v;}}
          >
            <DialogContent
              type={type}
              boxStyle={boxStyle}
              title={title}
              content={content}
              contentStyle={contentStyle}
              icon={icon}
              placeholder={placeholder}
              inputValue={inputValue}
              inputMaxValue={inputMaxValue}
              beforeInputText={beforeInputText}
              afterInputText={afterInputText}
              underInput={underInput}
              underInputWithCheck={underInputWithCheck}
              underInputWithCheckText={underInputWithCheckText}
              handelInput={handelInput}
              inputLimitType={inputLimitType}
              keyboardType={keyboardType}
              leftBtnText={leftBtnText}
              rightBtnText={rightBtnText}
              onLeftClick={onLeftClick}
              onRightClick={onRightClick}
              titleStyle={titleStyle}
              inputStyle={inputStyle}
              hideInputBottomBorder={hideInputBottomBorder}
              leftBtnStyle={leftBtnStyle}
              rightBtnStyle={rightBtnStyle}
              disabledRightBtnOnPromptEmpty={disabledRightBtnOnPromptEmpty}
              disabledRightBtnOnPromptZero={disabledRightBtnOnPromptZero}
              disabledRightBtnOnPromptPoint={disabledRightBtnOnPromptPoint}
              showDelete={showDelete}
              hide={()=>{
                if (dialogOverlayKey) {
                  Overlay.hide(dialogOverlayKey);
                  dialogOverlayKey = null;
                }
              }}
              rest={rest}
            />
          </Overlay.View>
        );
      }
      if (!dialogOverlayKey) {
        dialogOverlayKey = Overlay.show(dialogOverlay);
      }
      if (type !== 'reload' && type !== 'needReturn') {
        resolve();
      }
    });
  }
}

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Theme.isIPhoneX ? 40 : 30,
  },
});

