import { Platform } from 'react-native';

import Dialog from '@components/Dialog';
import { select } from './store';

// ios
// 'CAMERA': 'PERMISSIONS.IOS.CAMERA'
// 'ALBUM': 'PERMISSIONS.IOS.PHOTO_LIBRARY'

// android
// 'CAMERA': 'PERMISSIONS.ANDROID.CAMERA'
// 'ALBUM': 'PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE'
// 'WRITE': 'PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE'
// 'SAVE_android': PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE,
const check = async ({type, successCallBack, errorCallBack}) => {
  return requestPremission(type, successCallBack, errorCallBack);
};

const permissionMap = {
  
};

const contentKeyMap = {
  'CAMERA_ios': 'CAMERA_ios_perm_req',
  'CAMERA_android': 'CAMERA_android_perm_req',
  'ALBUM_ios': 'ALBUM_ios_perm_req',
  'ALBUM_android': 'ALBUM_android_perm_req',
  'SAVE_ios': 'SAVE_ios_perm_req',
  'SAVE_android': 'SAVE_android_perm_req',
};

const requestPremission = async (type, successCallBack = ()=>{}, errorCallBack = ()=>{}) => {
  
};


export default {
  check,
};
