import { Platform } from "react-native";

let generateTestId;

// iOS
if (Platform.OS === 'ios') {
  generateTestId = function(id){
    return { testID: id };
  };
}
// Android
else {
  generateTestId = function(id){
    return { accessibilityLabel: id };
  };
}

export let testId = generateTestId;
