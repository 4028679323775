
const stepper = (() => {
  let i = 0;
  return () => ++i;
})();

const eventListenerMap = {};

// handler: { name: string, data: object } => any
const addListener = (name, handler) => {
  if (!eventListenerMap[name]) {
    eventListenerMap[name] = [];
  }
  const id = stepper();
  const listener = {
    id,
    name,
    handler,
    remove: () => removeListener(name, id),
  };
  eventListenerMap[name].push(listener);
  return listener;
};

const removeListener = (name, id) => {
  if (eventListenerMap[name]) {
    eventListenerMap[name] = eventListenerMap[name].filter(i => i.id !== id);
  }
};

const addOnceListener = (name, handler) => {
  const listener = addListener(name, (...rest) => {
    listener.remove();
    handler(...rest);
  });
  return listener;
};

const dispatchEvent = ({ name, data }) => {
  if (eventListenerMap[name]) {
    [...eventListenerMap[name]].map(listener => {
      listener.handler({ name, data });
    });
  }
};

const AppEvent = {
  on: addListener,
  once: addOnceListener,
  dispatch: dispatchEvent,
};

export default AppEvent;

